import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { ChangeEvent } from 'react';
import debouce from "lodash.debounce";
import * as yup from "yup";
import { toast } from "react-toastify";
import { copy, edit, forwards, trash } from './assets';
import { apiCall } from "../../utilities/src/NetworkRequest";
import { getStorageData, setStorageData  } from "../../../framework/src/Utilities";
import { startOfToday , addDays, differenceInDays} from 'date-fns';
export interface ProgramCategoryType {
  attributes: {
    id: string;
    name: string;
    image: {
      id: string;
      url: string;
    }
  }
  id: string;
  type: string;
  selected: boolean;
}

export interface ContentType {
  id: number;
  type: string;
  attributes: {
    id: number;
    name: null | string;
    phase_number: number;
    content: [
      {
        id: number;
        lesson_type: string;
        type: string;
        title: string;
        duration: null | number;
        pdf_page: number;
        description: string;
        sequence_number: number
      }
    ]
  }
}
export interface ProgramUserType {
  id: string;
  type:string;
  attributes:{
    full_name:string;
    id:string|number;
    email:string;
    is_selected:boolean;
  }
}
export interface ExpertListing {
  id: string,
  type: string,
  attributes: {
    first_name: string,
    last_name: string,
    email: string,
    profession: string,
    full_name:string,
    avatar: null,
    is_admin: boolean,
  }
}
export interface AdminContentRoles {
  id: string;
     type: string;
     attributes:
      { id: number;
  uniq_id:string; 
  name:string; 
  description:string; 
  status:string;
  is_sellable: boolean;
  price: string;
  expert_id: number;
  expert_type: string;
  created_at: string;
  subscription_period: string;
  is_selected: boolean;
   thumbnail_image: null;
   };
   }
  
export interface AdminCatagoryListing {
  id: string,
  type: string,
  attributes: {
    name: string,
  }
}
interface ThumbnailImage {
  id: number | null;
  url: string;
}

interface CourseAttributes {
  average_rating: number;
  categories: string[];
  content_roles: string[];
  created_at: string; // ISO 8601 date string
  description: string;
  expand_type: string;
  expert_name: string;
  id: number;
  number: string;
  price: string;
  status: string;
  thumbnail_image: ThumbnailImage;
  title: string;
  total_chapters: number;
  updated_at: string; // ISO 8601 date string
  user_enrolled: number;
}

interface CourseDataItem {
  attributes: CourseAttributes;
  id: string;
  type: string;
}
interface CourseResponseJson {
  data: CourseDataItem[];
}
export interface CalendarValue {
  0: string | number | Date;
  1: string | number | Date;
}

export interface ProgramFormikData{
  title:string,
  expert_id: string,
  description: string,

  include_launch_date: boolean,
  launch_date: string|null,

  add_due_date: boolean,
  due_date: string | null,

  days_to_complete_the_program: boolean,
  program_timeline:  number

  is_notify_when_completed: boolean,
  notify_when_completed:any,

  content_release_schedule:boolean,
  content_release_schedule_value: string,
  phases:string,

  is_forced_learning: boolean,
  //keep_in_draft_stats: boolean,

  is_sellable:boolean,
  price: number,
  subscription_period: string,
  selectedCategories:Array<ProgramCategoryType>
}
interface ThumbnailImage {
  id: number | null;
  url: string;
}
export interface CoursePopupData{
  title:string,
  course_id: string,
  xpert_name: string,
  user_enrolled: number,
  status: string,
  updated_at: string,
  number: string,
  content_roles: string[];
  total_chapters: number,
  thumbnail_image: ThumbnailImage,
}

export interface ExpertType {
  id: string;
  type: string;
  attributes: {
    email: string;
    first_name: string;
    last_name: string;
    full_name:string;
    is_admin: boolean;
    profession: string | null;
    avatar: string | null;
  };
}
interface PaginationResponse {
  current_page: number;
  next_page: number | null;
  prev_page: number | null;
}

interface Meta {
  pagination: PaginationResponse;
}

interface Pagination {
  curPage: number;
  nextPage: number | null;
  prevPage: number | null;
}

interface ErrorHandlePayLoad {
  [key:string]:string
}

 interface CourseData {
  title: string;
  course_number?:string;
  description: string;
  is_sellable: boolean;
  price: string;
  is_forced_learning: boolean;
  is_notify_when_completed: boolean;
  notify_when_completed:any;
  time_constraint: boolean;
  add_preview: boolean;
  course_timeline: string;
  part_of_program: boolean;
  include_launch_date: boolean;
  // keepin_draft_state: boolean;
  launch_date: Date | null;
  categories: string;
  due_date: Date | null;
  content_release_schedule: boolean;
  content_release_schedule_value: string;
  add_thumbnail: boolean;
  add_due_date: boolean;
  add_to_xpand_collection: boolean;
  add_reward: boolean;
  expert_id: number;
  add_achievement: boolean;
  subscription_period: string;
  launchDateToggle: boolean;
  dueDateToggle: boolean;
  days_to_complete_the_course: boolean;
  phases: string;
  is_free_trial: boolean;
  isEdit: boolean;
  program_id: string[];
  start_date: Date;
  end_date: Date;
  thumbnail_image: ThumbnailImage;
  contents: [
    {
      id: string,
      type: string ,
      attributes: {
        id: number,
        name: null,
        phase_number: 1,
        content: []
      }
    }
  ];
}

interface CourseApiResponse {
  message: string | null;
  errors: Array<[ErrorHandlePayLoad]> | null;
  data: {
    id: string;
    type: string;
    attributes: {
      title: string;
      course_number: string;
      expert_id: number;
      description: string;
      include_launch_date: boolean;
      launch_date: string | null;
      add_due_date: boolean;
      due_date: string | null;
      days_to_complete_the_course: boolean;
      program_timeline: number;
      is_notify_when_completed: boolean;
      content_release_schedule: boolean;
      content_release_schedule_value: string | null;
      is_forced_learning: boolean;
      keep_in_draft_stats: boolean;
      is_sellable: boolean;
      price: string;
      status: string;
      learning_details: string | null;
      subscription_period: string;
      created_at: string;
      updated_at: string;
      created_by: string;
      notify_admin_list: string | null;
      average_rating: number;
      thumbnail_image: {
        id: number,
        url: string
      };
      categories: Array<{
        id: number;
        name: string;
      }>;
      content_roles: Array<{
        id: number;
        name: string;
      }>;
      lessons_count: number;
      associated_programs: Array<{
        id: number;
        name: string;
      }>;
      contents: Array<{
        id: string;
        type: string;
        attributes: {
          id: number;
          name: string | null;
          phase_number: number;
          content: Array<{
            id: number;
            title: string;
            description: string;
          }>;
        };
      }>;
    };
  } | null;
  status: number;
  error: string | null;
}
export interface AttributesType {
  title: string;
  course_id: string;
  xpert_name: string;
  content_roles: string[];
  total_chapters: number;
  user_enrolled: number;
  updated_at: string;
  status: string;
  number: string;
  thumbnail_image: ThumbnailImage;
}

export interface ContentRolesType {
  id: string,
  type: string,
  attributes: AttributesType;
}
export interface CheckboxSelectedListType { value: string, id: string }

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  location?: any;
  history?: any;
  match?: { params: any };
  formikProps: any;
  historyProp: any;
  handleThumbnailChange: any;
  handleLaunchDate: any;
  handleDueDate: any;
  onDraft:any;
  showCoursePopup: boolean;
  courseResponse: CoursePopupData[],
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  expanded: boolean;
  selectedCourseID: string; 
  selectedCourseName: string;
  RemovePhase: boolean;
  isCourseActive: string;
  pagination: Pagination;
  meta1: any;
  list: any;
  timeline: any;
  imgVidUrl: any;
  imgVidFile: any;
  filename: any;
  category: any;
  courseId: any;
  open: boolean;
  data: any;
  icons: any;
  toggle: boolean;
  page: any;
  rowsPerPage: any;
  image: any;
  imgUrl: any
  img: any
  file: any
  id: any
  isStatus:boolean
  programs: any
  tags: any
  tagText: string
  content_value:any;
  courseTitle:string;
  courseData:any;
  contentReleaseScheduleData: boolean;
  courseStatus:string;
  phasesContent:any
  lessonCount: Array<ContentType>;
  contentRolesData:Array<ContentRolesType>;
  course: any
  createdCourseID:any,
  backupPhases: any
  droppableId: string
  rewardImg: any
  achievmentImg: any
  programList: any,
  start_date_show: boolean,
  end_date_show: boolean,
  isLoading: boolean,
  notificationList: any,
  adminCollection: any,
  contentRoleList: any,
  meta: any,
  text: any,
  searchtext: string,
  thumbnail:any,
  isDragEnabled:boolean;
  selectedContentRole: any
  createFolder: any,
  folderModalOpen: any,
  reward: any,
  folderData: any;
  openAlert: boolean;
  editingIndex: any, // Initially, no index is being edited
  editingText: any;
  openPhaseDelete:boolean;
  openLessonDelete:boolean;
  isHovering:any;
  phaseID:any;
  phaseName:string;
  lessonName:string;
  lessonID:any
  formikDataLocal: any;
  launchDate:any;
  dueDate:any;
  phase_type: string;
  phase_type_id: number;
  program_phase: number;
  course_phase: number;
  openLesson:boolean;
  previousLessonId:string;
  existing_course: any;
  existing_program: any;
  reward_list: any;
  achievement_list: any;
  Achievement: any;
  openAchievement: boolean;
  backupVidoes: any;
  backupProgramIncludes: any;
  addContentVideoData: any;
  showRenameDialog:any;
  hoverLessonId: string;
  withPhaseDraftModal: boolean;
  createCourseModal: boolean;
  createCourseModalDraft: boolean;
  addDialogBoxopen: boolean;
  dialogImageToggle: boolean;
  hoverPhaseId: string;
  addDialogPhaseBoxopen: boolean;
  dialogPhaseImageToggle: boolean;
  modalOpen: boolean;
  phasesIncluded: boolean;
  showPhassesDropDown: string;
  showPhasesDialogLesson: string;

  fileInput: React.RefObject<HTMLInputElement>;
  programThumbnailImage:string;
  programAllExperts: Array<ExpertType>;
  programSelectedExpert: (ExpertType | null);
  programCategory: Array<ProgramCategoryType>;
  programLaunchDate: Date|null;
  programDueDate: Date|null;
  programUserList: Array<ProgramUserType>;
  withoutPhaseId: any;
  isEditMode:boolean;
  daysToComplete:number;
  toastMsg: string;
  allData: CourseData;
  formSecondPageDta: object;
  createNewTitle: string;
  editTrue:boolean;
  isLessonAdded: string;
  saveDraftContentModal:boolean;
  saveToDraftProgramValues: ProgramFormikData;
  isSubmitted: boolean;
  showThumbnailPopup:boolean,
  thumbnailId:any,
  anchorEl:boolean,
  anchorElPopup: null | HTMLElement;
  placeholderSearchText:string,
  showCoursePopup:boolean,
  anchorFilterContainerEl: null | HTMLElement,
  sortConfig: {
    key: keyof AttributesType;
  direction: 'asc' | 'desc';
  };
  courseResponse: CoursePopupData[],
  initialCourseResp: CoursePopupData[],
  courseAddedResponse: CoursePopupData[],
  selectedRows: string[];
  filterItems: boolean;
  showList: boolean;
  filteredList: Array<string>;
  creationDate: string;
  creationDateParams: {
    start_date: string;
    end_date: string;
  };
  categorySelectedItems: Array<CheckboxSelectedListType>;
  placeholderSelectedItems: Array<CheckboxSelectedListType>;
  contentRoleSelectedItems: Array<CheckboxSelectedListType>;
  selectedStatus: string;
  contentRolesParams: string;
  catagoryParams: string,
  placeholderParams: string;
  contentRoleSearchText: string;
  categorySearchText: string;
  dropdownType: string;
  isCalendarOpen: boolean;
  adminCatagoryList: Array<AdminCatagoryListing>;
  expertAdminList: Array<ExpertListing>;
  responseData: Array<CoursePopupData>;
  adminContentRolesList: Array<AdminContentRoles>;
  programTitle: string;
  programStatus: string;
  contentRoleTrue:boolean;
  selectedCourseRows: { id: string, name: string; status: string }[]; 
  courseAdded:{id: string, name: string; status: string }[];
  courseToRemove: {id: string, name: string; status: string }[];
  coursesSelected: boolean;
  formDirty:boolean;
  EditCourseContent:boolean;
  showDiscardModal:boolean;
  lessonLength:number;
  first_time:boolean;
   // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CourseCreationController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getCourseAndProgramApiCallId: string = "";
  getCategoryApiCallId: string = "";
  getCreateCourseApiCallId: string = "";
  getCreateCourse1ApiCallId: string = "";
  getCreateProgramApiCallId: string = "";
  getCreateProgram1ApiCallId: string = "";
  getProgramDetailApiCallId: string = "";
  getProgramUpdateApiCallId: string = "";
  getProgramUpdate1ApiCallId: string = "";
  getUpdateCourseApiCallId: string = "";
  getUpdateCourse1ApiCallId: string = "";
  getCourseDetailApiCallId: string = "";
  getContentCourseApiCallId: string = "";
  getContentProgramApiCallId: string = "";
  adminContentRolesListingAPICallId: string = "";
  reorderPhaseContentsApiCallId: string = "";
  contentRolesApiCallId:string = "";
  addRewardApiCallId: string = "";
  deletePhaseApiCallId:string="";
  deleteLessonApiCallId:string="";
  renamePhaseApiCallId:string = "";
  addAchievementApiCallId: string = "";
  addPreviewApiCallId: string = "";
  getProgramListApiCallId: string = "";
  getAdminNotifyListApiCallId: string = "";
  getContentRoleListApiCallId: string = "";
  createFolderApiCallId: string = "";
  getFolderApiCallId: string = "";
  getDeleteVideoApiCallId: string = "";
  getDeleteQuizApiCallId: string = "";
  getCreateObjectApiCallId: string = "";
  changePhaseOrderApiCallId:string ="";
  changeLessonOrderApiCallId:string= "";
  getTagListApiCallId: string = "";
  getDeleteObjectApiCallId: string = "";
  getDeleteCourseApiCallId: string = "";
  getExistingCourseListApiCallId: string = "";
  getRewardListApiCallId: string = "";
  getSaveCourseApiCallId:string = "";
  getAchievementListApiCallId: string = "";
  reorderProgramontentsApiCallId: string = "";
  getSASTokenApiCallId: string = "";
  getApiCallId: string = "";
  formData: any;
  addPhaseApiCallId:string="";
  expertApiCallId :string = "";
  getCatalogueAdvanceSearchApiCallId: string = "";
  expertAdminListingAPICallId: string = "";
  adminCatagoryListingAPICallId: string = "";
  formikRef:  any;
  createdProgramID: number| string = "";
  getDiscardApiCallId:string=""
    // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    
    // Customizable Area Start
    this.handleCategoryResponse = this.handleCategoryResponse.bind(this);
    this.handleCreateCourseResponse = this.handleCreateCourseResponse.bind(this);
    this.handleCreateProgram1Response = this.handleCreateProgram1Response.bind(this);
    this.handleUpdateCourseResponse = this.handleUpdateCourseResponse.bind(this);
    this.handleCreateFolderOpen = this.handleCreateFolderOpen.bind(this);
    this.handleCreateFolderClose = this.handleCreateFolderClose.bind(this);
    this.handleAchievementReponse = this.handleAchievementReponse.bind(this);
    this.handleRewardReponse = this.handleRewardReponse.bind(this);
    this.handleGetDeleteVideoQuizResponse = this.handleGetDeleteVideoQuizResponse.bind(this);
    this.handleFolderResponse = this.handleFolderResponse.bind(this);
    this.handleGetFolderResponse = this.handleGetFolderResponse.bind(this);
    this.handleGetProgramResponse = this.handleGetProgramResponse.bind(this);
    this.ProgramUpdateResponseHandle = this.ProgramUpdateResponseHandle.bind(this);
    this.createCategory = this.createCategory.bind(this);
    this.handleContentRoleListResponse = this.handleContentRoleListResponse.bind(this);
    this.handleAdminNotifyListResponse = this.handleAdminNotifyListResponse.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage)
      // Customizable Area End
    ];

    this.state = {
      pagination: {
        curPage: 1,
        nextPage: null,
        prevPage: null,
      },
      meta1: "",
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      expanded: true,
      isCourseActive: "course",
      courseAddedResponse: [],
      list: [
        { id: 1, text: "Include Launch Date", key: "include_launch_date" },
        { id: 2, text: "Add Due Date", key: "add_due_date" },
        { id: 3, text: "Notify When completed", key: "notify_when_completed" },
        { id: 4, text: "Content release Schedule", key: "content_release_schedule" },
        { id: 5, text: "Forced Learning", key: "forced_learning" },
        { id: 6, text: "Add to Xpand Collection", key: "add_to_xpand_collection" },
        { id: 7, text: "Part of a Program", key: "part_program" },
        { id: 8, text: "Add Reward", key: "add_reward" },
        { id: 9, text: "Add Achievement", key: "add_achievement" },
        { id: 10, text: "Add Preview/Ad", key: "add_preview" },
        { id: 11, text: "Make this course sellable", key: "course_sellable" }
      ],
      timeline: [
        { id: 1, text: "Pick a Prompt", key: "pick_program" },
      ],
      imgVidUrl: "",
      imgVidFile: '',
      filename: "",
      category: [],
      formikDataLocal: {},
      courseId: "",
      open: false,
      openPhaseDelete:false,
      openLessonDelete:false,
      openLesson:false,
      previousLessonId:"0",
      phaseID:"default",
      lessonID:"",
      phaseName:"",
      lessonName:"",
      data: [
        { content: "", selected: false, file: "" },
        { content: "", selected: false, file: "" },
        { content: "", selected: true, file: "" },
        { content: "", selected: false, file: "" }
      ],
      icons: [],
      toggle: false,
      page: 2,
      rowsPerPage: 5,
      image: "",
      imgUrl: "",
      id: "",
      file: "",
      img: "",
      programs: [],
      tags: [],
      tagText: "",
      createdCourseID:this.props.navigation?.getParam('id'),
      phasesContent:[],
      lessonCount: [],
      isDragEnabled:false,
      adminContentRolesList: [],
      course: "",
      backupPhases: {},
      droppableId: "",
      searchtext:"",
      rewardImg: '',
      achievmentImg: '',
      programList: [],
      contentRolesData:[],
      thumbnail:"",
      start_date_show: false,
      end_date_show: false,
      isLoading: false,
      notificationList: [],
      adminCollection: [],
      contentRoleList: [],
      reward: {},
      editingIndex: null, // Initially, no index is being edited
      editingText: "",
      openAchievement: false,
      Achievement: {},
      meta: "",
      text: "",
      selectedContentRole: [],
      createFolder: '',
      folderModalOpen: false,
      folderData: '',
      openAlert: false,
      launchDate:"",
      dueDate:"",
      phase_type: "",
      isStatus:false,
      phase_type_id: 0,
      program_phase: 0,
      course_phase: 0,
      isHovering:"",
      existing_course: [],
      existing_program: [],
      reward_list: [],
      achievement_list: [],
      backupVidoes: [],
      backupProgramIncludes: [],
      addContentVideoData: [],
      hoverLessonId: "",
      withPhaseDraftModal: false,
      createCourseModal: false,
      createCourseModalDraft: false,
      addDialogBoxopen: false,
      dialogImageToggle: false,
      hoverPhaseId: "",
      addDialogPhaseBoxopen: false,
      dialogPhaseImageToggle: false,
      modalOpen: false,
      content_value:[],
      courseTitle:"",
      courseData:"",
      contentReleaseScheduleData: false,
      courseStatus:"",
      phasesIncluded: false,
      showPhassesDropDown: "",
      showPhasesDialogLesson: "",
      showRenameDialog:"",

      fileInput: React.createRef(),
      programThumbnailImage:"",
      programAllExperts: [],
      programSelectedExpert: null,
      programCategory: [],
      programLaunchDate: null,
      programDueDate: null,
      programUserList: [],
      isEditMode:false,
      daysToComplete:0,
      withoutPhaseId:"",
      toastMsg: "",
      createNewTitle:"",
      allData:{
        title: '',
        course_number:"",
        description: '',
        is_sellable: false,
        price: '',
        is_forced_learning: false,
        is_notify_when_completed: false,
        notify_when_completed: [],
        time_constraint: false,
        add_preview: false,
        course_timeline: '',
        part_of_program: false,
        include_launch_date: false,
        // keepin_draft_state: false,
        launch_date:null ,
        categories: '',
        due_date: null,
        content_release_schedule: false,
        content_release_schedule_value: '',
        add_thumbnail: false,
        add_due_date: false,
        add_to_xpand_collection: false,
        add_reward: false,
        expert_id: 1,
        add_achievement: false,
        subscription_period: '',
        launchDateToggle: false,
        dueDateToggle: false,
        days_to_complete_the_course: false,
        phases: '',
        is_free_trial: false,
        isEdit: false,
        program_id: [],
        start_date: new Date(),
        end_date: new Date(),
        contents: [
          {
            id: "",
            type:"" ,
            attributes: {
              id: 0,
              name: null,
              phase_number: 1,
              content: []
            }
          }
   
      
        ],
        thumbnail_image :{
          id: 0,
          url:""
        },
     
      },
      formSecondPageDta:{},
      editTrue:false,
      isLessonAdded: "",
      saveDraftContentModal:false,
      saveToDraftProgramValues:{
        title: "",
        expert_id: "",
        description: "",
        include_launch_date: false,
        launch_date: null,
        add_due_date: false,
        due_date: null,
        days_to_complete_the_program: false,
        program_timeline: 0,
        is_notify_when_completed: false,
        notify_when_completed: [],
        content_release_schedule: false,
        content_release_schedule_value: "",
        phases: "",
        is_forced_learning: false,
        is_sellable: false,
        price: 0,
        subscription_period: "",
        selectedCategories: [],
      },
      isSubmitted: false,
      showThumbnailPopup:false,
      thumbnailId:"",
      anchorEl:false,
      placeholderSearchText:"",
      showCoursePopup: false,
      anchorFilterContainerEl: null,
      sortConfig: {
        key: 'title', 
        direction: 'asc',
      },
      courseResponse:[],
      initialCourseResp:[],
      selectedRows: [],
      filterItems: true,
      showList: false,
      filteredList: [],
      creationDate: "",
      creationDateParams: {
        start_date: "",
        end_date: ""
      },
      categorySelectedItems: [],
      placeholderSelectedItems: [],
      contentRoleSelectedItems: [],
      selectedStatus: "",
      contentRolesParams: "",
      catagoryParams: "",
      placeholderParams: "",
      contentRoleSearchText: "",
      categorySearchText: "",
      dropdownType: "",
      isCalendarOpen: false,
      anchorElPopup: null,
      adminCatagoryList: [],
      expertAdminList: [],
      responseData: [],
      programTitle: "",
      programStatus: "",
      contentRoleTrue:false,
      selectedCourseRows: [] ,
      courseAdded:[],
      courseToRemove:[],
      coursesSelected:false,
      RemovePhase: false,
      selectedCourseID: "", 
      selectedCourseName: "",
      formDirty:false,
     // Customizable Area End
      EditCourseContent:false,
      showDiscardModal:false,
      lessonLength:0,
      first_time:true,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    const updateData= await getStorageData("UpdateData")
    if(!updateData)
    {
      setStorageData("UpdateData",JSON.stringify({
        save:false,
        lengthCount:0,
        phaseCount:0
      }))
    }
    setStorageData("courseCreationDirtyVal", this.state.formDirty.toString());
    this.getTagsListData()
    this.getCategoryListData()
    window.scrollTo(0, 0)
    this.getAdminNotifyList("");
    this.getProgramList();
    this.getTags();
    this.getExpertSearchListNew();
    this.getAdminExpertListing();
    this.getAdminCategoryListing();
    this.getAdminContentRolesListing({ search: "", page: "", per: "", }, this.state.pagination.curPage);
    this.getCourseContent(this.state.createdCourseID);
    this.createdProgramID = await getStorageData("currentProgramID");
   this.getProgramDetail(this.createdProgramID);
    const  currentID = this.props.navigation.getParam('id')
    const isLessonAdded = this.props.navigation.getParam("lesson")
    this.setState({
      isLessonAdded
    })
    if(currentID){
      this.getCourseDetail(currentID)
      this.setState({
        isLoading: false
      })
    }
    if (this.state.searchtext === "") 
     {
      this.getCourse({ search: "", filter: "course", page: "", per: "", }, this.state.pagination.curPage)
    }
    const currentUser = await getStorageData('userData',true)
    this.setState({ programSelectedExpert: currentUser })
    if (this.state.text === "") {
      // this.getExistingCourseList({ search: "", filter: "course", page: "", per: "", }, this.state.pagination.curPage)
    }
    if (this.props?.navigation?.getParam('id') === "new") {
      this.getContentRole({ course_id: "", search: "", per: "", page: "" })
    }
    else {
      this.getContentRole({ course_id: this.props?.navigation?.getParam('id'), search: "", per: "", page: "" })
    }
    if (this.props?.location?.state?.selectedContentRole) {
      this.setState({ selectedContentRole: this.props?.location?.state?.selectedContentRole })
    }
    if (this.props?.location?.state?.formObject) {
      this.setState({ isLoading: false });
    }
    if (this.props?.navigation?.getParam('type') === "login" || this.props?.navigation?.getParam('id') === "login") {
      this.props?.navigation?.navigate('EmailAccountLoginBlock');
    }
    if (!this.props?.location?.state?.categories) {
      this.getCategory()
    }
    else {
      this.setState({ category: this.props?.location?.state?.categories });
    }
    if (this.props.navigation.getParam('id') !== "new" && this.props.navigation.getParam('type') === "addProgram") {
      setTimeout(() => {
        this.getProgramDetail(this.props.navigation.getParam('id'))
      }, 2000)

    }
    else if (this.props.navigation.getParam('id') !== "new" && this.props.navigation.getParam('type') === "editCourse" && !this.props?.location?.state?.categories) {
      setTimeout(() => {
        this.getCourseDetail(this.props.navigation.getParam('id'))
        this.getAdminNotifyList(this.props.navigation.getParam('id'))
      }, 1000)

    }
    else if (window.location.pathname === `/BuildCourse/${this.props.navigation.getParam('id')}`) {
      setTimeout(() => {
        this.getCourseDetail(this.props.navigation.getParam('id'))
      }, 2000)
    }
    else if (window.location.pathname === `/BuildProgram/${this.props.navigation.getParam('id')}`) {
      setTimeout(() => {
        this.getProgramDetail(this.props.navigation.getParam('id'))
      }, 2000)
    }
    else {
      this.setState({ programs: [] })
    }
  }

  async receive(from: string, message: Message) {
     
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.getCategoryApiCallId === apiRequestCallId) {
        this.handleCategoryResponse(responseJson);
      } else if (this.getCreateCourseApiCallId === apiRequestCallId) {
        this.handleCreateCourseResponse(responseJson);
      }
      else if (this.getUpdateCourseApiCallId === apiRequestCallId) {
        this.handleUpdateCourseResponse(responseJson);
      }
      else if (this.getCreateCourse1ApiCallId === apiRequestCallId) {
        this.handleCreateCourse1Response(responseJson);
} else if (this.getCreateProgramApiCallId === apiRequestCallId) {
        this.handleCreateProgramResponse(responseJson);
      } else if (this.getCreateProgram1ApiCallId === apiRequestCallId) {
        this.handleCreateProgram1Response(responseJson);
      } 
      else if (this.getContentProgramApiCallId === apiRequestCallId) {
        this.handleExistingProgramListResponse(responseJson)
      }
      else if (this.getExistingCourseListApiCallId === apiRequestCallId) {
        this.handleExistingCourseListResponse(responseJson)
      }
      
      else if (this.getUpdateCourse1ApiCallId === apiRequestCallId) {
        this.handleUpdate1CourseResponse(responseJson);
      }
      else if (this.getCourseDetailApiCallId === apiRequestCallId) {
        this.handleGetCourseDetailResponse(responseJson);
      }
      else if (this.getProgramUpdateApiCallId === apiRequestCallId) {
        this.ProgramUpdateResponseHandle(responseJson);

      } else if (this.getProgramUpdate1ApiCallId === apiRequestCallId) {
        this.SecProgramUpdateResponseHandle(responseJson);

      }
      else if (this.getProgramDetailApiCallId === apiRequestCallId) {
        this.ProgramDetailResponse(responseJson)
        await  setStorageData("currentProgramID", responseJson.data.id)
      }
      else if (this.addRewardApiCallId === apiRequestCallId) {
        this.setState({ reward: responseJson.data }, () =>
          this.handleRewardReponse(responseJson))
      }
      else if (this.addAchievementApiCallId === apiRequestCallId) {
        this.setState({ Achievement: responseJson.data }, () =>
          this.handleAchievementReponse(responseJson)
        )
      }
      else if (this.getProgramListApiCallId === apiRequestCallId) {
        this.handleProgramListResponse(responseJson)
      }

      else if (this.addPreviewApiCallId === apiRequestCallId) {
        this.handlePreviewReponse(responseJson);
      }
      else if (this.createFolderApiCallId === apiRequestCallId) {
        this.handleFolderResponse(responseJson)
      }
      else if (this.getFolderApiCallId === apiRequestCallId) {
        this.handleGetFolderResponse(responseJson)
      }
      else if (this.getDeleteVideoApiCallId === apiRequestCallId) {
        this.handleGetDeleteVideoQuizResponse(responseJson)
      }
      else if (this.getDeleteQuizApiCallId === apiRequestCallId) {
        this.handleGetDeleteVideoQuizResponse(responseJson)
      }
      else if (this.getDeleteObjectApiCallId === apiRequestCallId) {
        this.handleGetDeleteObjectCourseResponse(responseJson);
      }
      else if (this.getDeleteCourseApiCallId === apiRequestCallId) {
        this.handleGetDeleteObjectCourseResponse(responseJson);
      }
      else if (this.getCreateObjectApiCallId === apiRequestCallId ) {
        this.handleCreateObjectResponse(responseJson)
      }
      else if (this.getTagListApiCallId === apiRequestCallId) {
        this.handleTagListResponse(responseJson)
      }
      else if (this.getContentCourseApiCallId === apiRequestCallId) {
        this.handleCourseContentResponse(responseJson)
      }
      else if ( this.addPhaseApiCallId === apiRequestCallId) {
        this.getCourseContent(this.state.createdCourseID)
      }
      else if ( this.changeLessonOrderApiCallId === apiRequestCallId) {
        this.getCourseContent(this.state.createdCourseID)
      }
      else if ( this.changePhaseOrderApiCallId === apiRequestCallId) {
        this.getCourseContent(this.state.createdCourseID)
      }
      else if ( this.renamePhaseApiCallId === apiRequestCallId) {
        this.handleRenamePhaseResponse(responseJson)
      }
      else if ( this.deletePhaseApiCallId === apiRequestCallId) {
        this.handleDeletePhaseResponse(responseJson)
      }
      else if ( this.deleteLessonApiCallId === apiRequestCallId) {
        this.handleDeleteLessonResponse(responseJson)
      }
      else if (this.reorderPhaseContentsApiCallId === apiRequestCallId) {
        // Re-order items in build course
        const phaseExists = this.state.course.attributes.videos.length === 0;
        if (phaseExists) {
          this.handleReorderPhaseContentsResponse(responseJson);
        } else {
          this.handleReorderVideosResponse(responseJson);
        }
      }
      else if (this.getAdminNotifyListApiCallId === apiRequestCallId) {
        this.handleAdminNotifyListResponse(responseJson);
      }
      else if (this.getContentRoleListApiCallId === apiRequestCallId) {
        this.handleContentRoleListResponse(responseJson);
      } else if (this.reorderProgramontentsApiCallId === apiRequestCallId) {
        const phaseExists = this.state.programs.attributes.program_includes.length === 0;
        phaseExists ?
          this.handleReorderProgramContentsResponseWithPhases(responseJson) :
          this.handleReorderProgramContentsResponseWithoutPhases(responseJson);
      } else if (this.getSASTokenApiCallId === apiRequestCallId) {
        this.handleGetSASTokenResponse(responseJson);
      } else if (this.expertApiCallId === apiRequestCallId) {
        this.handleExpertResponse(responseJson.data)
      }
      else if (this.getCourseAndProgramApiCallId === apiRequestCallId) {
        this.handleCourseDataResponse(responseJson);   
      }
      else if (this.expertAdminListingAPICallId === apiRequestCallId) {
      
        this.setState({
          expertAdminList: responseJson.data
        })
      }
      else if (this.adminCatagoryListingAPICallId === apiRequestCallId) {
        this.setState({
          adminCatagoryList: responseJson.data
        })

      }
      else if (this.adminContentRolesListingAPICallId === apiRequestCallId) {
        this.setState({
          adminContentRolesList: responseJson.data
        })
      }
      if (this.contentRolesApiCallId === apiRequestCallId) {
        this.setState({
          contentRolesData: responseJson.data
        })
      }
      if (this.getCatalogueAdvanceSearchApiCallId === apiRequestCallId) {
     {this.filteredListResponse(responseJson.data)}   
      }
      if(this.getDiscardApiCallId === apiRequestCallId){
       this.getDiscardApiSuccess(responseJson)
      }
      if(this. getSaveCourseApiCallId === apiRequestCallId){
         this.handleSaveChanges()
       }
     
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }
  

  tostSuccessfully = () => {
    toast.success("Coming soon");
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  handleMakeActive=(status:any)=>{
    const payload = new FormData();
    payload.append("course[status]", status);
    this.saveCreateCourse({
      contentType: configJSON.validationApiContentType,
      method: configJSON.PutMethod,
      endPoint: configJSON.updateCourseAPiEndPoint + this.state.createdCourseID,
      body: payload
    });
  }
  handleOpenAchievement = () => {
    this.setState({ openAchievement: true })
  };

  filterContainerDropdownCloseHandler = () => {
    this.setState({
      anchorFilterContainerEl: null
    })
  }

handleCourseOpen = () => {
  this.getCourse({ search: '', filter: '', page: '1', per: '10' }, 1);
  this.setState({
    showCoursePopup: true,
    selectedRows: [],
  });
}

  handleCloseAchievement = () => {
    this.setState({ openAchievement: false })
  };

  // Customizable Area Start
  handleAdminNotifyListResponse(responseJson: any) {
    if (responseJson && responseJson.data && responseJson.data.length > 0) {
      this.setState({ notificationList: responseJson.data, programUserList:responseJson.data });
    }
  }
  handleContentRoleListResponse(responseJson: any) {
    if (responseJson && responseJson.data && responseJson.data.length > 0) {
      this.setState({ contentRoleList: responseJson.data, meta: responseJson.meta });
    } else {
      this.setState({ contentRoleList: [], meta: responseJson?.meta })
    }
  }

  handleGetFolderResponse(responseJson: any) {
    if (responseJson && responseJson.data) {
      this.setState({
        folderData: responseJson.data
      })
    }
  }
  toPascalCase = (str: string) => {
    return str
      ?.toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join('');
  };
  getStatusStyle = (status: string) => {
    switch (status?.toLowerCase()) {
      case 'active':
        return { backgroundColor: '#E0D4E7', color:'#542070 '};
      case 'draft':
        return { backgroundColor: '#FFEDE4', color:'#E47004' };
      case 'archived':
        return { backgroundColor: '#FFD9DF', color:'#E70E6B' };
      default:
        return {};
    }
  };
  
  doGetCourseAndProgram(data: {
    contentType?: string;
    endPoint?: string;
    method?: string;
    type?: string;
    body?: {};

  }) {
    const { contentType, method, endPoint } = data;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCourseAndProgramApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  getCourse(params: { search: string, filter: string, page: string, per: string }, pagination: number | Pagination) {
    let customEndPoint = this.constructEndPoint(params, pagination);


    this.doGetCourseAndProgram({
      method: configJSON.apiMethodTypeGet,
      contentType: configJSON.validationApiContentType,
      endPoint: configJSON.courseAPiEndPoint + customEndPoint,
    });

    return customEndPoint;

  }

  constructEndPoint(params: { search: string, filter?: string, page: string, per: string }, pagination: number | Pagination): string {
    let endPoint = `?course_type=${params.filter}&per=${params.per}&page=${pagination}`;
    if (params.search) {
      endPoint += `&search=${params.search}`;
    }
    endPoint += this.addStatusParam();
    endPoint += this.addPlaceholderParams();
    endPoint += this.addContentRolesParams();
    endPoint += this.addCategoryParams();
    endPoint += this.addCreationDateParam();
    endPoint += this.addCreationDateRangeParam();

    return endPoint;

  }
  handleClearFilter = () => {
    this.setState({
      anchorFilterContainerEl: null,
      selectedStatus: "",
      contentRolesParams: "",
      catagoryParams: "",
      contentRoleSelectedItems: [],
      contentRoleSearchText: "",
      categorySelectedItems: [],
      categorySearchText: "",
      placeholderSelectedItems: [],
      placeholderParams: "",
      placeholderSearchText: '',
      creationDate: "",
      isCalendarOpen: false,
      anchorElPopup: null,
      dropdownType: "",
      creationDateParams: {
        start_date: "",
        end_date: ""
      }
    }, () => {
      if(this.state.contentRoleTrue){
        this.getAdminContentRolesListing({ 
          page: "", 
          search: "", 
          per: "" },1);    
      }else{
        this.getCourse({
          page: "",
          search: this.state.text,
          filter: this.state.isCourseActive,
          per: "",
        }, 1);
      }
     
    })
  }
  handleToggleContentRole = () => {
    this.setState({
      isCourseActive: "contentRole",
      contentRoleTrue:true
    },()=>{this.getAdminContentRolesListing({ search: "", page: "", per: "", }, this.state.pagination.curPage)})
    this.handleClearFilter()

  };
filteredListResponse= (responseJson:any) => {
  const filterList = responseJson?.filter((item: string) => 
    !this.state.courseAddedResponse.some(course => course.title === item))
  this.setState({
    filteredList: filterList,

  })   
}
  getAdminContentRolesListing(params: { search: string, page: string, per: string }, pagination: number | Pagination) {
    let customEndPoint = this.constructEndPoint(params, pagination);
    this.doAdminContentRolesListing({
      method: configJSON.apiMethodTypeGet,
      contentType: configJSON.validationApiContentType,
      endPoint: configJSON.contentRoleListingApiEndPoint,
    });
    this.doContentRolesListing({
      method: configJSON.apiMethodTypeGet,
      contentType: configJSON.validationApiContentType,
      endPoint: configJSON.contentRoleListingApiEndPoint + customEndPoint,
    })
    // return customEndPoint;
  }
  handleClickFilter = () => {
    if(this.state.contentRoleTrue){
    this.getAdminContentRolesListing({ 
      search: "", 
      page: "", 
      per: "" },1);
      
    }else{
      this.getCourse({
        search: this.state.text,
        filter: this.state.isCourseActive,
        page: "",
        per: "",
      }, 1);
    }
  
    this.setState({
      anchorFilterContainerEl: null
    })
  }
  doAdminContentRolesListing(data: {
    contentType?: string;
    type?: string;
    method?: string;
    body?: {};
    endPoint?: string;
  }) {
    const { contentType, method, endPoint } = data;
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.adminContentRolesListingAPICallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  doContentRolesListing(data: {
    method?: string;
    contentType?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.contentRolesApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  private addPlaceholderParams(): string {
    return this.state.placeholderParams ? `&${this.state.placeholderParams}` : '';
  }
  private addStatusParam(): string {
    return this.state.selectedStatus ? `&q[status]=${this.state.selectedStatus}` : '';
  }
  private addCategoryParams(): string {
    return this.state.catagoryParams ? `&${this.state.catagoryParams}` : '';
  }

  private addContentRolesParams(): string {
    return this.state.contentRolesParams ? `&${this.state.contentRolesParams}` : '';
  }
  private addCreationDateRangeParam(): string {
    const { start_date, end_date } = this.state.creationDateParams;
    if (start_date && end_date) {
      const rangeObj = {
        start_date: start_date,
        end_date: end_date
      };
      const queryString = encodeURIComponent(JSON.stringify(rangeObj));
      return `&q[custom_range]=${queryString}`;
    }
    return '';
  }
  private addCreationDateParam(): string {
    const dateParam = this.getDateParam();
    return dateParam ? `&q[created_at]=${dateParam}` : ''
  }
  private getDateParam(): string {
    switch (this.state.creationDate) {
      case "Last Week":
        return "last_week";
      case "Last Month":
        return "last_month";
      case "Last Year":
        return "last_year";
      default:
        return '';
    }
  }

  handleTagListResponse(responseJson: any) {
    if (responseJson && responseJson.data && responseJson.data.length > 0) {
      const tags = responseJson.data?.map((item: any) => {
        return { id: item.id, type: item.type, attributes: item.attributes, isSelected: false }
      });
      this.setState({ tags: tags });
    } else {
      this.setState({ tags: [] })
    }
  }

 async handleCourseContentResponse(responseJson: any) {
    if (responseJson.data) {
      const courseContent=responseJson.data
      const contentLength:any=await getStorageData("UpdateData");
      const data=JSON.parse(contentLength)
      if(data.save === false){
      setStorageData("UpdateData",JSON.stringify({
        save:false,
        lengthCount:courseContent.attributes.contents[0].attributes.content.length,
        phaseCount:courseContent.attributes.contents.length
      }))
    }
      this.setState({courseData:courseContent,first_time:false,courseTitle:courseContent.attributes.title,courseStatus:courseContent.attributes.status,contentReleaseScheduleData:courseContent.attributes.content_release_schedule
      })
      if(courseContent.attributes.content_release_schedule){
        this.setState({
          phasesContent:courseContent.attributes.contents,
          lessonCount:courseContent.attributes.contents.filter((content:ContentType)=> content.attributes.content.length)
        })
      }else{
        this.setState({content_value:courseContent.attributes.contents[0].attributes.content})
      }
    }
  }
  contentRoleCheckboxHandleChange
   = (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const { checked, name } = event.target;
    if (!checked)  {
      this.setState(prevState => ({
        contentRoleSelectedItems: prevState.contentRoleSelectedItems.filter((item: { value: string, id: string }) => item.id !== itemId)
      }), () => {
        const params = this.state.contentRoleSelectedItems.map((item: { value: string, id: string }) => `q[content_role_ids][]=${item.id}`).join("&");
        this.setState({
          contentRolesParams: params
        });
      });
    } else {
      this.setState(prevState => ({
        contentRoleSelectedItems: [...prevState.contentRoleSelectedItems, { value: name, id: itemId }]
      }), () => {
        const params = this.state.contentRoleSelectedItems.map((item: { value: string, id: string }) => `q[content_role_ids][]=${item.id}`).join("&");
        this.setState({
          contentRolesParams: params
        });
      });
    } 
  }

  handleRenamePhaseResponse(responseJson: any){
    if (!responseJson.errors) {
      this.getCourseContent(this.state.createdCourseID)
      this.setState({showRenameDialog:""})
    }else{
      toast.error(responseJson.errors[0].message, { autoClose: 2000 });
      this.setState({showRenameDialog:""})
    }
  }
  handleDeletePhaseResponse(responseJson: any){
    if (responseJson) {
      toast.success(responseJson.message, { autoClose: 2000 });
      this.getCourseContent(this.state.createdCourseID)
      this.setState({openPhaseDelete:false,phaseID:"",phaseName:""})
    }else{
      toast.error(responseJson.message, { autoClose: 2000 });
    }
  }
  handleDeleteLessonResponse(responseJson: any){
    if (responseJson) {
      toast.success(responseJson.message, { autoClose: 2000 });
      this.getCourseContent(this.state.createdCourseID)
      this.setState({openLessonDelete:false,lessonID:"",lessonName:""})
    }else{
      toast.error(responseJson.message, { autoClose: 2000 });
    }
  }
  handleFolderResponse(responseJson: any) {
    if (responseJson) {
      this.handleCreateFolderClose()
      this.getFolderData()
    }
  }

  creationChangeHandlerDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      creationDate: event.target.value
    })
  }

  handleCatalogueChangeStatus = (status: string) => {
    this.setState({
      selectedStatus: status,
      anchorElPopup: null,
      dropdownType: ""
    })
  }
  handleContentRoleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      contentRoleSearchText: event.target.value
    })
  }
  categoryCheckboxHandlerChange = (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const { checked, name } = event.target;
    this.setState(prevState => {
      let updatedItems;
      if (!checked) {
        updatedItems = prevState.categorySelectedItems.filter(item => item.id !== itemId);
      } else {
        updatedItems = [...prevState.categorySelectedItems, { value: name, id: itemId }];
      }  


      const params = this.buildCategoryParams(updatedItems);
      return { categorySelectedItems: updatedItems, catagoryParams: params };
    });
  }
  buildCategoryParams = (items: { value: string, id: string }[]) => {
    const categoryIdsKey = 'category_ids_c' ;
    return items.map(item => `q[${categoryIdsKey}][]=${item.id}`).join('&');
  }

  handleCategoryChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      categorySearchText: event.target.value
    })
  }
  handlePlaceholderAssignedSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      placeholderSearchText: event.target.value
    }, () => {
      this.placeholderDebounced();
    })
  }
  placeholderDebounced: () => void = debouce(
    () => this.getAdminExpertListing(),
    700
  )
  handleOpenCalendor = () => {
    this.setState({
      isCalendarOpen: true
    })
  }
  getAdminExpertListing() {
    this.doAdminExpertListing({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.expertAdminListApiEndPoint + `?search=${this.state.placeholderSearchText}`,
    });
  }
  doAdminExpertListing(data: {
    contentType?: string;
    body?: {};
    method?: string;
    type?: string;
    endPoint?: string;

  }) {
    const { contentType, method, endPoint } = data;
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.expertAdminListingAPICallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  handleChangeCalendor = (value: CalendarValue) => {
    const startDate = new Date(value[0]);
    const endDate = new Date(value[1]);
    const formattedStartDateNum = startDate.toLocaleDateString("en-US", { day: "2-digit" });
    const formattedStartMonthNum = startDate.toLocaleDateString("en-US", { month: "2-digit" });
    const formattedStartDate = startDate.getDate();
    const formattedEndDate = endDate.getDate();
    const formattedEndDateNum = endDate.toLocaleDateString("en-US", { day: "2-digit" });
    const formattedStartYear = startDate.getFullYear();
    const formattedEndYear = endDate.getFullYear();
    const formattedEndMonthNum = endDate.toLocaleDateString("en-US", { month: "2-digit" });
    const formattedEndMonth = endDate.toLocaleDateString("en-US", { month: "long" });
    const dateRange = `${formattedStartDate}-${formattedEndDate} ${formattedEndMonth} ${formattedEndYear}`;

    const start_date = `${formattedStartYear}-${formattedStartMonthNum}-${formattedStartDateNum}`;
    const end_date = `${formattedEndYear}-${formattedEndMonthNum}-${formattedEndDateNum}`;
    this.setState({
      creationDateParams: {
        start_date,
        end_date
      },
      creationDate: dateRange,
    });
  }


  placeholderCheckboxAssignedExpertChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const { checked, name } = event.target;
    if (!checked)  {
      this.setState(prevState => ({
        placeholderSelectedItems: prevState.placeholderSelectedItems.filter((item: { value: string, id: string }) => item.id !== itemId)
      }), () => {
        const params = this.state.placeholderSelectedItems.map((item: { value: string, id: string }) => `q[expert_ids][]=${item.id}`).join("&");
        this.setState({
          placeholderParams: params
        });
      });
    } else {
      this.setState(prevState => ({
        placeholderSelectedItems: [...prevState.placeholderSelectedItems, { value: name, id: itemId }]
      }), () => {
        const params = this.state.placeholderSelectedItems.map((item: { value: string, id: string }) => `q[expert_ids][]=${item.id}`).join("&");
        this.setState({
          placeholderParams: params
        });
      });
    }  
  }
  handleExistingCourseListResponse(responseJson: any) {
    if (responseJson && responseJson.data && responseJson.data?.length > 0) {
      const data = responseJson.data.map((item: any) => {
        return {
          type: [
            { value: "checkbox", size: "18" },
            { value: "icon", size: "40", url: item?.attributes?.thumbnail_image?.url },
          ],
          "title": { value: item?.attributes?.title, size: "100px" },
          "course_number": { value: item?.attributes?.course_number, size: "100px" },
          "expert_name": { value: item?.attributes?.expert_name, size: "100px" },
          "content_roles": { value: item?.attributes?.content_roles, size: "100px" },
          "total_chapters": { value: item?.attributes?.total_chapters, size: "50px" },
          "user_enrolled": { value: item?.attributes?.user_enrolled, size: "50px" },
          "summary": { value: "", size: "30px" },
          "id": item.id,
          "is_selected": item?.attributes?.is_selected,
          actions: [{ value: "chip", size: "40", status: item?.attributes?.status }]
        }

      });
      const pagination: Pagination = {
        curPage: responseJson.meta.pagination.current_page,
        nextPage: responseJson.meta.pagination.next_page,
        prevPage: responseJson.meta.pagination.prev_page,
      }
      this.setState({ existing_course: data, meta: responseJson.meta, pagination })

    }
    else if (responseJson && responseJson.data && responseJson.data?.length === 0) {
      toast.error("Data not found", { delay: 700 })
      this.setState({ existing_course: responseJson.data, meta: "" });
      this.getExistingCourseList({ search: "", filter: "", page: 1, per: 10 }, this.state.pagination.curPage)
    }
    else {
      if (responseJson.errors) {
        let value = responseJson.errors.map((item: any) => Object.keys(item))
        toast.error(responseJson.errors[0][value[0]], {
          autoClose: 2000
        })
      }
    }
  }
  handleExistingProgramListResponse(responseJson: any) {
    if (responseJson && responseJson.data?.attributes) {
      const pagination: Pagination = {
        curPage: responseJson.meta.pagination.current_page,
        nextPage: responseJson.meta.pagination.next_page,
        prevPage: responseJson.meta.pagination.prev_page,
      };
  
      this.setState({ existing_program: responseJson.data?.attributes, meta: responseJson.meta, pagination });
    } else if (responseJson && responseJson.data && responseJson.data.length === 0) {
      toast.error("Data not found", { delay: 700 });
      this.setState({ existing_program: responseJson.data, meta: "" });
      this.getExistingCourseList({ search: "", filter: "", page: 1, per: 10 }, this.state.pagination.curPage);
    } else {
      if (responseJson.errors) {
        const errorKey = Object.keys(responseJson.errors[0])[0];
        toast.error(responseJson.errors[0][errorKey], {
          autoClose: 2000
        });
      }
    }
  }
  handleNextPageNavigation = () => {
    this.setState((curState) => {
      return {
        ...curState,
        pagination: {
          ...curState.pagination,
          curPage: curState.pagination.curPage + 1,
        }
      }
    })
  }

  // Handle navigation to the prev page in pagination
  handlePrevPageNavigation = () => {
    this.setState((curState) => {
      return {
        ...curState,
        pagination: {
          ...curState.pagination,
          curPage: curState.pagination.curPage - 1,
        }
      }
    })
  }

  componentDidUpdate(
    
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined,
  ): void {
    // User has clicked the next of prev button
    if (this.state.pagination?.curPage !== prevState?.pagination.curPage) {
      this.getExistingCourseList({ search: "", filter: "course", page: "", per: "", }, this.state.pagination.curPage)
    }
    if (this.props.location !== prevProps?.location) {
      this.componentDidMount();
    }  
    if (prevState?.formDirty !== this.state.formDirty) {
      setStorageData("courseCreationDirtyVal", this.state.formDirty.toString());
    }  
  }
  
  handleRenameText=(e:any)=>{
    this.setState({ editingText: e.target.value })
  }
  handleSaveName = (id:any,value:any) => {
    let data={
      "phase":{
        "name":value
      }
    }
    this.renamePhase({
      contentType: configJSON.validationApiContentType,
      method: configJSON.PutMethod,
      endPoint: configJSON.renamePhaseApiEndPoint+id,
      body: JSON.stringify(data),
    });
    this.setState({
      editingIndex: null,
      editingText: ""
    });
  }
  renamePhase(data: any) {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.renamePhaseApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleDeletePhase=(id:any)=>{
    this.deletePhase({
      method: configJSON.DeleteMethod,
      endPoint: configJSON.renamePhaseApiEndPoint+id,
    });
  }
  deletePhase(data: any) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.deletePhaseApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleDeleteLesson=(id:any)=>{
    this.deleteLesson({
      method: configJSON.DeleteMethod,
      endPoint: configJSON.deleteLessonApiEndPoint+id,
    });
  }
  deleteLesson(data: any) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.deleteLessonApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleEditClick = (index:any, name:any) => {
    this.setState({
      editingIndex: index,
      editingText: name || ""  // Initialize with the current name or an empty string
    });
  }



  SecProgramUpdateResponseHandle(SecresponseJson: any) {
    if (SecresponseJson && SecresponseJson.data) {
      toast.success("Program update successfuly", {
        autoClose: 2000
      });
      this.props.navigation.navigate(`ContentManagement`, { id: SecresponseJson.data.id })
    } else {
      if (SecresponseJson.errors) {
        let value = SecresponseJson.errors.map((item: any) => Object.keys(item))
        toast.error(SecresponseJson.errors[0][value[0]], {
          autoClose: 2000
        })
      }
    }
  }

  ProgramUpdateResponseHandle(responseJson: any) {
    if (responseJson && responseJson.data) {
      toast.success("Program update successfuly", {
        autoClose: 2000
      });
      this.props.navigation.navigate(`BuildProgram`, { id: responseJson.data.id })
    } else {
      if (responseJson.errors) {
        let value = responseJson.errors.map((item: any) => Object.keys(item))
        toast.error(responseJson.errors[0][value[0]], {
          autoClose: 2000
        })
      }
    }
  }
  createCategory = (item: any, selected: any) => {
    return {
      id: item.id,
      type: item.type,
      attributes: item.attributes,
      selected,
      featured: true,
    };

  };
  ProgramDetailResponse(responseJson: any) {

    if (responseJson && responseJson.data) {
      this.setState({programTitle:responseJson.data.attributes.title});
      this.setState({programStatus:responseJson.data.attributes.status,      
      });
      const checkCategory = (id: any) => {
        const b = responseJson.data.attributes?.categories.some((pro: any) => pro.attributes.id === id);
        return b;
      };
      const contentRoles = responseJson.data.attributes?.content_roles || [];
      const { selectedContentRole = [] } = this.props?.location?.state || {};
      const items = contentRoles.map(({ name, id }: any) => ({ content_role: name, id }));
      const categories = this.state.category?.map((item: any) => {
        let selected;
        if (this.state.course?.attributes?.categories.length === 0) {
          selected = item.selected;
        } else {
          selected = checkCategory(item.attributes?.id) ? !item.selected : item.selected;
        }
        return this.createCategory(item, selected);
      });
      const selectedItems = [...selectedContentRole, ...items].filter(
        ({ content_role, id }, index, a) =>
          a.findIndex((e) => content_role === e.content_role && id === e.id) === index
      );
    
      this.setState({ programs: responseJson.data, isLoading: false, category: categories, selectedContentRole: selectedItems },()=> {
      });
    }
  }
  handleProgramListResponse(responseJson: any) {
    if (responseJson && responseJson.length > 0) {
      this.setState({ programList: responseJson })
    }
    else {
      this.setState({ programList: [] })
    }
  }
  handlePreviewReponse = (responseJson: any) => {
    if (responseJson) {
      toast.success("Preview Added successfully");
      this.props.history.push(`${this.props.location.state.path}`, { formikdata: this.props.location.state.formikData })
    }
  }
  handleRewardReponse = (data: any) => {
    if (data) {
      toast.success("Reward Added successfully");
      // this.props.navigation.goBack();
    } this.props.history.push(`${this.props.location.state.path}`, { formikdata: this.props.location.state.formikData, reward: this.state.reward.id })
    // this.props.navigation.goBack();
  }
  handleAchievementReponse = (responseJson: any) => {
    if (responseJson) {
      toast.success("Achievement Added successfully");
      // this.props.navigation.goBack();
      this.props.history.push(`${this.props.location.state.path}`, { formikdata: this.props.location.state.formikData, achievement: this.state.Achievement.id })
    }
  }
  handleCategoryResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      const category = responseJson.data && responseJson.data.length > 0 && responseJson?.data?.map((item: any) => {
        return {
          id: item.id, type: item.type, attributes: item.attributes,
          selected: false,
          featured: true
        }
      });
      this.setState({ category: category, programCategory: [...category] });
    } 
  }

  handleCreateCourse1Response = (SecresponseJson: any) => {
    if (SecresponseJson && SecresponseJson.data) {
      toast.success("Course create successfuly", {
        autoClose: 2000
      })
      this.props.navigation.navigate(`ContentManagement`, { id: SecresponseJson.data.id })
    } else {
      if (SecresponseJson.errors) {
        let value = SecresponseJson.errors.map((item: any) => Object.keys(item))
        toast.error(SecresponseJson.errors[0][value[0]], {
          autoClose: 2000
        })
      }
    }
  }

  handleCreateCourseResponse = async(responseJson: CourseApiResponse) => {
    this.setState({
      formSecondPageDta: responseJson
    });
    let isSaveDraft =  await getStorageData("isSaveDraft")
    if (responseJson && responseJson.data) { 
      let withOutPhaseId = responseJson.data.attributes.contents[0].id

     await setStorageData("withoutPhaseId", withOutPhaseId);
     
        if(isSaveDraft === "true"){
          this.props.navigation.navigate("ContentManagement");
          toast.success(`${this.state.toastMsg} saved as draft`, {
            autoClose: 2000
          })
        } 
        else {
           if (responseJson.data.attributes.content_release_schedule) {
        await  setStorageData("currentCourseID", responseJson.data.id)
        this.props.navigation.navigate("AddContentPage", {
          type: "Phases", id: responseJson.data.id, lesson: "lesson"
        });
      } 
      else {
        await  setStorageData("currentCourseID", responseJson.data.id)
         this.props.navigation.navigate("AddContentPage", {type: "AddPhases",id: responseJson.data.id, lesson: "lesson"});
         toast.success("Course create successfully", {
          autoClose: 2000
        })
        }
      }
    }
    else {
      if (responseJson.errors) {
        let value = responseJson.errors.map((item: any) => Object.keys(item))
        toast.error(responseJson.errors[0][value[0] as unknown as number], {
          autoClose: 2000
        })
      }else{
        toast.error(responseJson.message, {
          autoClose: 2000
        })
      }
    }
  }

  handleCreateProgramResponse = (responseJson: CourseApiResponse) => {
    if (responseJson && responseJson.data) {
      toast.success("Program created successfully", {
        autoClose: 2000
      })
      const { id } = responseJson.data;
      const { title } = responseJson.data.attributes;
      this.props.navigation.navigate('ProgramContent', {
        id,       
       });
    } else {
      if (responseJson.errors) {
        let value = responseJson.errors.map((item: any) => Object.keys(item))
        toast.error(responseJson.errors[0][value[0] as unknown as number], {
          autoClose: 2000
        })
      }
      else{
        toast.error(responseJson.message, {
          autoClose: 2000
        })
      }
    }
  }
  secondsToHHMMSS(seconds: number) {
    if (isNaN(seconds) || seconds < 0) {
      return "00:00:00 Hr";
    }

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')} Hr`;
    return formattedTime;
  }
  
  handleCreateProgram1Response = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      toast.success(`${responseJson.data.attributes.title} saved as draft`, {
        autoClose: 2000
      })
      this.props.navigation.navigate(`ContentManagement`, { id: responseJson.data.id })
    } else {
      if (responseJson.errors) {
          let titleErrorExists = responseJson.errors.some((item: object) =>
        item.hasOwnProperty('title')
      );
      if (titleErrorExists) {
        // Suppress the toast for title error
        return;
      }
        let value = responseJson.errors.map((item: any) => Object.keys(item))
        toast.error(responseJson.errors[0][value[0]], {
          autoClose: 2000
        })
      }
      else{
        toast.error(responseJson.message, {
          autoClose: 2000
        })
      }
    }
  }

  handleUpdateCourseResponse = async(responseJson: any) => {
    if (responseJson && responseJson.data) {
      toast.success("Course update successfuly", {
        autoClose: 2000
      });
      let withOutPhaseId = responseJson.data.attributes.contents[0].id

     await setStorageData("withoutPhaseId", withOutPhaseId);
     const savebtn:any= await getStorageData("save&close")
     const saveDraft=await getStorageData("isSaveDraft")
     if(savebtn===true|| saveDraft==="true"){
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "ContentManagement");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
     }else
      if (responseJson.data.attributes.content_release_schedule) {
        await  setStorageData("currentCourseID", responseJson.data.id)
        await setStorageData("EditCourseContent",true)
        this.props.navigation.navigate("AddContentPage", {
          type: "Phases", id: responseJson.data.id,lesson:"lessonAdded"
        });
      } else {
        await  setStorageData("currentCourseID", responseJson.data.id)
        await setStorageData("EditCourseContent",true)
         this.props.navigation.navigate("AddContentPage", {type: "AddPhases",id: responseJson.data.id,lesson:"lessonAdded"});
         toast.success("Course create successfully", {
          autoClose: 2000
        })
        }
    } else {
      if (responseJson.errors) {
        let value = responseJson.errors.map((item: any) => Object.keys(item))
        toast.error(responseJson.errors[0][value[0]], {
          autoClose: 2000
        })
      }
      else if (responseJson.message) {
        toast.error(responseJson.message, {
          autoClose: 2000
        })
      }
    }
  }
  handleUpdate1CourseResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      toast.success("Course update successfuly", {
        autoClose: 2000
      });
      this.props.navigation.navigate(`ContentManagement`, { id: responseJson.data.id })
    } else {
      if (responseJson.errors) {
        let value = responseJson.errors.map((item: any) => Object.keys(item))
        toast.error(responseJson.errors[0][value[0]], {
          autoClose: 2000
        })
      }
      else if (responseJson.message) {
        toast.error(responseJson.message, {
          autoClose: 2000
        })
      }
    }
  }
  handleCreateObjectResponse = (responseJson: any) => {
    this.setState({ isLoading: false });
    if (responseJson && responseJson.data) {
      toast.success("Object has been created successfully", {
        autoClose: 2000
      });
      this.props.navigation.navigate(`BuildProgram`, { id: this.props?.navigation?.getParam('id') })
    } else {
      if (responseJson.error) {
        responseJson.error.map((item: any) => {
          toast.error(item.message, {
            autoClose: 2000
          });
        });
      }
      else if (responseJson.message) {
        toast.error(responseJson.message, {
          autoClose: 2000
        })
      } else {
        toast.error("Something went wrong", {
          autoClose: 2000
        })
      }
    }
  }

  handleGetCourseDetailResponse(responseJson: any) {
    if (responseJson && responseJson.data) {
      this.setState({
        allData: responseJson.data.attributes,
        thumbnail:responseJson.data.attributes.thumbnail_image_id,
       
      })
      this.setState(prevState => ({
        allData: {
            ...prevState.allData,
            notify_when_completed: responseJson.data.attributes.notify_admin_list &&  responseJson.data.attributes.notify_admin_list.map((i:any)=>i.id) // Replace with the new array
        }
    }));
    
      const { selectedContentRole = [] } = this.props?.location?.state || {};
      const checkCategory = (id: any) => {
        const b = responseJson.data.attributes.categories.some((pro: any) => pro.id === id);
        return b;
      };
      const contentRoles = responseJson.data.attributes?.content_roles || [];
      const items = contentRoles.map(({ name, id }: any) => ({ content_role: name, id }));
      const selectedItems = [...selectedContentRole, ...items].filter(
        ({ content_role, id }, index, a) =>
          a.findIndex((e) => content_role === e.content_role && id === e.id) === index
      );

      const categories = this.state.category?.map((item: any) => {
        let selected;
        if (this.state.course?.attributes?.categories.length === 0) {
          selected = item.selected;
        } else {
          selected = checkCategory(item.attributes.id) ? !item.selected : item.selected;
        }
        return this.createCategory(item, selected);
      });

      this.setState({ course: responseJson.data, isLoading: false, category: categories, selectedContentRole: selectedItems });
    }
  }

  handleGetProgramResponse(responseJson: any) {
    if (responseJson && responseJson.data && responseJson.data.id === this.createdProgramID) {
      toast.success("Program update successfuly", { autoClose: 2000 });
    } else {
      let value = responseJson.errors.map((item: any) => Object.keys(item))
      toast.error(responseJson.errors[0][value[0]], {
        autoClose: 2000
      })
    }
  }
  handleGetDeleteVideoQuizResponse(responseJson: any) {
    if (responseJson) {
      this.getCourseDetail(this.props.navigation.getParam('id'))
    } else {
      let value = responseJson.errors.map((item: any) => Object.keys(item))
      toast.error(responseJson.errors[0][value[0]], {
        autoClose: 2000
      })
    }
  }
  handleGetDeleteObjectCourseResponse(responseJson: any) {
    if (responseJson && responseJson.data) {
      this.getProgramDetail(this.props.navigation.getParam('id'))
    } else {
      let value = responseJson.errors.map((item: any) => Object.keys(item))
      toast.error(responseJson.errors[0][value[0]], {
        autoClose: 2000
      })
    }
  }
  getTags() {
    this.doGetTagList({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getTagListApiEndPoint,
    });
  }
  doGetTagList(data: any) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getTagListApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  getExistingCourseList({ search, filter, page, per }: any, pagination: any) {
    this.doGetExistingCourseList({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getExistingCourseListApiEndPoint + `?search=${search}&sort=${filter}&page=${pagination}&per=${per}`,
    });
  }
  doGetExistingCourseList(data: any) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getExistingCourseListApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  async getCourseContent(id: any) {
    let storedData = await getStorageData("UpdateData");
    if (storedData) {
      storedData = JSON.parse(storedData);
      storedData.save = true;
      setStorageData("UpdateData", JSON.stringify(storedData));
    }

    this.dogetCourseContent({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getContentCourseApiEndPoint + id.toString(),
    });
  }
  dogetCourseContent(data: any) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getContentCourseApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  getProgramContent(id: number | string) {  
    this.dogetProgramContent({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getContentProgramApiEndPoint + id.toString(),
    });
  }
  dogetProgramContent(data: any) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getContentProgramApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  // Create objects
  getCreateObject(data: any) {
    this.setState({ isLoading: true });
    const fileTypeVideo = this.state.file && this.state.file.type.includes("video");
    if (fileTypeVideo) {
      this.formData = data;
      // Get the token for azure upload
      this.getSASToken();
    } else {
      this.doCreateObject({
        contentType: undefined,
        method: configJSON.PostMethod,
        endPoint: configJSON.createObjectApiEndPoint,
        body: data,
      });
    }
  }

  // Api call to create object
  doCreateObject(data: any) {
    const { method, endPoint, body } = data;
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCreateObjectApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  getProgramList() {
  this.doGetProgramList({
  contentType: configJSON.validationApiContentType,
  method: configJSON.validationApiMethodType,
  endPoint: configJSON.getProgramListApiEndPoint,
  });
  }
  doGetProgramList(data: any) {
  const { contentType, method, endPoint } = data;
  const header = {
  "Content-Type": contentType,
  token: localStorage.getItem("token"),
  };
  const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)

  );
  requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
  );
  this.getProgramListApiCallId = requestMessage.messageId
  requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  endPoint
  );
  requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  method
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
  }
  getAdminNotifyList(data: any) {
    this.doGetAdminNotifyList({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: data === "" ? configJSON.getAdminNotificationList : configJSON.getAdminNotificationList + `?course_id=${data}`,
    });
  }
  doGetAdminNotifyList(data: any) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getAdminNotifyListApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  getCategory() {
    if (typeof (this.props?.location?.state?.item) !== undefined) {
      this.setState({
        courseId: this.props.location?.state?.item,
      }, () => {
        //callback
      })
    }

    this.doGetCategoryUser({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.categoryFetchAPiEndPoint,
    });
  }
  doGetCategoryUser(data: any) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCategoryApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  createCourse(data: any) {
    this.doCreateCourse({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.createCourseAPiEndPoint,
      body: data
    });
  }
  doCreateCourse(data: any) {
    const { method, endPoint, body } = data;
    const header = {
      "Content-Type": undefined,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCreateCourseApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  saveToDraftcreateCourse(data: any) {
    this.doCreateCourse1({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.createCourseAPiEndPoint,
      body: data
    });
  }
  doCreateCourse1(data: any) {
    const { method, endPoint, body } = data;
    const header = {
      "Content-Type": undefined,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCreateCourse1ApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  updateCourse(data: any, id: any) {
    this.doUpdateCourse({
      contentType: configJSON.validationApiContentType,
      method: configJSON.PutMethod,
      endPoint: configJSON.updateCourseDetailApiEndPoint + `/${id}`,
      body: data
    });
  }
  doUpdateCourse(data: any) {
    const { method, endPoint, body } = data;
    const header = {
      "Content-Type": undefined,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getUpdateCourseApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  SaveToDraftCourse(data: any, id: any) {
    this.doUpdateCourse1({
      contentType: configJSON.validationApiContentType,
      method: configJSON.PutMethod,
      endPoint: configJSON.updateCourseDetailApiEndPoint + `/${id}`,
      body: data
    });
  }
  doUpdateCourse1(data: any) {
    const { method, endPoint, body } = data;
    const header = {
      "Content-Type": undefined,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getUpdateCourse1ApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getCourseDetail(id: any) {
    this.doGetCourseDetail({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getCourseDetailApiEndPoint + `/${id}`,
    });
  }
  doGetCourseDetail(data: any) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCourseDetailApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }


validateProgramFormikData(data:ProgramFormikData){
  if(data.selectedCategories.length<1){
    toast.error("Please add at least one category")
    return false
  }
  return true;
}
isEnableCreate(data:ProgramFormikData){
  if(!data.title || data.title.length<5 ){
    return false
  }
  if(data.selectedCategories.length<1){
    return false
  }
  return true;
}

getFormData(formValues:ProgramFormikData){
  const formData = new FormData();
  formData.append('program[title]', formValues.title);
  formData.append('program[description]', formValues.description);
  formData.append('program[include_launch_date]', formValues.include_launch_date.toString());
  formValues.include_launch_date && formValues.launch_date &&formData.append('program[launch_date]', formValues.launch_date.toString())
  formData.append('program[add_due_date]', formValues.add_due_date.toString());
  formValues.add_due_date && formValues.due_date&&formData.append('program[due_date]', formValues.due_date.toString());
  formData.append('program[days_to_complete_the_program]', formValues.days_to_complete_the_program.toString());
  formValues.days_to_complete_the_program && formData.append('program[course_timeline]', formValues.program_timeline.toString())
  formData.append('program[is_notify_when_completed]', formValues.is_notify_when_completed.toString());  
    if (formValues.is_notify_when_completed) {
      for (const userId of formValues.notify_when_completed) {
        formData.append("admin_user_id[]", userId);
      }
  }      
  formData.append('admin_user_id[]', formValues.notify_when_completed);
  formData.append('program[content_release_schedule]', formValues.content_release_schedule.toString());
  if(formValues.content_release_schedule){
    formData.append('program[content_release_schedule_value]', formValues.content_release_schedule_value);
    formData.append('program[number_of_phases]', formValues.phases);
  }
  formData.append("thumbnail_image_id",this.state.thumbnailId)
  formData.append("content_role_ids[]", "4")
  formData.append('program[is_forced_learning]', formValues.is_forced_learning.toString())
  formData.append('program[program_for_sale]', formValues.is_sellable.toString());
  if(formValues.is_sellable){
    formData.append('program[price]',formValues.price.toString());

    formData.append("program[subscription_period]", formValues.subscription_period);
  }
  this.state.saveDraftContentModal && formData.append("program[status]", 'draft')

  formValues.selectedCategories.forEach((category:ProgramCategoryType )=>{
    formData.append("categories_id[]", category.id)
  })
  if(this.state.programSelectedExpert){
    formData.append('program[expert_id]',formValues.expert_id)
  }
  return formData
}

handleSubmit(values: any) {
  if(!this.validateProgramFormikData(values)){  
    return
  }

  const formData = this.getFormData(values)
  this.doCreateProgram({
    contentType: configJSON.validationApiContentType,
    method: 'POST',
    endPoint: configJSON.createProgramAPiEndPoint,
    body: formData
  });
}

doCreateProgram(data: any) {
  this.setState({saveDraftContentModal:false})
    const { method, endPoint, body } = data;
    const header = {
      "Content-Type": undefined,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCreateProgramApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  handleOpensaveAsDraft = (data:ProgramFormikData)=>{
    this.setState({saveDraftContentModal:true , saveToDraftProgramValues:data}) 
   }
  handleCloseSaveAsDraft = ()=>{
    this.setState({saveDraftContentModal:false})
  }

  emptyFieldCheck=()=>{
    return 
  }

   doCreateProgram1 = () => {
    this.setState({isSubmitted:true})
    this.setState({saveDraftContentModal:false})
     const {saveToDraftProgramValues} = this.state; 
     const formData = this.getFormData(saveToDraftProgramValues);
     if(saveToDraftProgramValues.title){
      const header = {
        token: localStorage.getItem("token"),
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
  
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      this.getCreateProgram1ApiCallId = requestMessage.messageId
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createProgramAPiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      formData &&
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formData
        );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return requestMessage.messageId;
     }
    
  }

  updateProgram(data: any, id: any) {
    this.doUpdateProgram({
      contentType: configJSON.validationApiContentType,
      method: configJSON.PutMethod,
      endPoint: configJSON.updateProgramDetailApiEndPoint + `/${id}`,
      body: data
    });
  }
  doUpdateProgram(data: any) {
    const { method, endPoint, body } = data;
    const header = {
      "Content-Type": undefined,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getProgramUpdateApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  getData = (data: any) => {
    if (data.length > 0) {
      this.setState({ adminCollection: data })
    }
  }

  saveToDraftupdateProgram(data: any, id: any) {
    this.doUpdateProgram1({
      contentType: configJSON.validationApiContentType,
      method: configJSON.PutMethod,
      endPoint: configJSON.updateProgramDetailApiEndPoint + `/${id}`,
      body: data
    });
  }
  doUpdateProgram1(data: any) {
    const { method, endPoint, body } = data;
    const header = {
      "Content-Type": undefined,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getProgramUpdate1ApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getProgramDetail(id: number| string) {
    this.doGetProgramDetail({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getProgramDetailApiEndPoint + `/${id}`,
    });
  }
  doGetProgramDetail(data: any) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getProgramDetailApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  doGetIcons(data: any) {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCategoryApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  addReward(user: any) {
    let payload = new FormData()
    payload.append("reward[name]", user.name)
    payload.append("reward[description]", user.description);
    if (this.props?.location?.state?.courseId) {
      const courseId = [this.props?.location?.state?.courseId]
      courseId.forEach((ele: any) => {
        payload.append("reward[course_ids][]", ele);
      })
    }
    else if (this.props?.location?.state?.programId) {
      const programId = [this.props?.location?.state?.programId];
      programId.forEach((ele: any) => {
        payload.append("reward[program_ids][]", ele);
      });
    }
    this.state.data.forEach((value: any, index: any) => {
      if (value.content) {
        payload.append(`reward[reward_contents_attributes][${index}][content]`, value.content)
      } else if (value.file) {
        payload.append(`reward[reward_contents_attributes][${index}][image]`, value.file, value.file.name)
      }
    })
    if (this.state.rewardImg) {

      payload.append("reward[thumbnail_image]", this.state.rewardImg, this.state.rewardImg.name)
    }


    const header = {
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.addRewardApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addRewardsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      payload
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return requestMessage.messageId;

  }

  addAchievment(user: any) {
    let data = new FormData()
    if (this.props?.location?.state?.courseId) {
      const courseId = [this.props?.location?.state?.courseId]
      courseId.forEach((ele: any) => {
        data.append("achievement[course_ids][]", ele);
      })
    }
    else if (this.props?.location?.state?.programId) {
      const programId = [this.props?.location?.state?.programId];
      programId.forEach((ele: any) => {
        data.append("achievement[program_ids][]", ele);
      });
    }
    data.append("achievement[name]", user.name)
    data.append("achievement[description]", user.description)
    if (this.state.achievmentImg) {

      data.append("achievement[thumbnail_image]", this.state.achievmentImg, this.state.achievmentImg.name)
    }


    const header = {
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.addAchievementApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addAchievmentApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return requestMessage.messageId;

  }

  addPreview() {
    let data = new FormData()
     if (this.props?.location?.state?.programId) {
      const programId = [this.props?.location?.state?.programId];
      programId.forEach((ele: any) => {
        data.append("course[program_ids][]", ele);
      });
    }
    else if (this.props?.location?.state?.courseId) {
      const courseId = [this.props?.location?.state?.courseId]
      courseId.forEach((ele: any) => {
        data.append("course[course_ids][]", ele);
      })
    }
    data.append("course[preview_file]", this.state.imgVidFile, this.state.filename);

    const header = {
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.addPreviewApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateCourseDetailApiEndPoint + `/1`
      // configJSON.updateCourseDetailApiEndPoint+ `/${this.props.navigation.getParam('id')}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return requestMessage.messageId;

  }

  changeStatus=(value:boolean)=>{
    this.setState({isStatus:value,formDirty:true})
  }

  // Update courses with phases to state
  updatePhasesToState = (newPhases: any) => {
    this.setState(curState => ({
      ...curState,
      course: {
        ...curState.course,
        attributes: {
          ...curState.course.attributes,
          phases: newPhases
        }
      }
    }));
  }

  // Update programs with phases to state
  updateProgramPhasesToState = (newPhases: any) => {
    this.setState(curState => ({
      ...curState,
      programs: {
        ...curState.programs,
        attributes: {
          ...curState.programs.attributes,
          phases: newPhases
        }
      }
    }));
  }

  // Api call to change the sequence of contents on the server
  reorderPhaseContents = (data: any) => {

    const token = localStorage.getItem("token");

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.reorderPhaseContentsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reorderPhaseContetnsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  // Handle the reponse of re order the course contents with phases
  handleReorderPhaseContentsResponse = (response: any) => {
    if (response && response.phases) {
      // Update sequence numbers of cur phase
      const phase = this.state.droppableId;
      const updatedPhases = { ...this.state.course?.attributes?.phases };
      updatedPhases[phase] = response.phases[phase];
      this.updatePhasesToState(updatedPhases);
    } else if (!response.phases) {
      // Replace the cur phase with the backup
      toast.error("Something went wrong");
      const phase = this.state.droppableId;
      const updatedPhases = { ...this.state.course?.attributes?.phases };
      updatedPhases[phase] = this.state.backupPhases[phase];
      this.updatePhasesToState(updatedPhases);
    }
  }

  // Handle the reponse of re order the course contents with phases
  handleReorderProgramContentsResponseWithPhases = (response: any) => {
    const currentPhase = this.state.droppableId;
    const updatedPhases = { ...this.state.programs.attributes.phases };
    if (response && response.phases) {
      // Update sequence numbers of cur phase
      updatedPhases[currentPhase] = response.phases[currentPhase];
      this.updateProgramPhasesToState(updatedPhases);
    } else if (!response.phases) {
      // Replace the cur phase with the backup
      toast.error("Something went wrong");
      updatedPhases[currentPhase] = this.state.backupPhases[currentPhase];
      this.updateProgramPhasesToState(updatedPhases);
    }
  }

  // Handle the response of re order the course contents without phases i.e. vidoes only
  handleReorderVideosResponse = (response: any) => {
    const responseOk = response && response.videos;
    if (!responseOk) {
      toast.error("Something went wrong");
    }

    // Update new sequence if reponse is ok, restore the backup otherwise
    this.setState({
      course: {
        ...this.state.course,
        attributes: {
          ...this.state.course.attributes,
          videos: responseOk ? response.videos.phase : this.state.backupVidoes,
        }
      }
    });
  }

  // Api call to change the sequence of contents on the server
  reorderProgramContents = (data: any) => {

    const token = localStorage.getItem("token");

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.reorderProgramontentsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reorderProgramContetnsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  // Handle the reponse of re order the program contents w/o phases
  handleReorderProgramContentsResponseWithoutPhases = (response: any) => {
    const responseOk = response && response.program_includes;
    if (!responseOk) {
      toast.error("Something went wrong");
    }

    // Update new sequence if reponse is ok, restore the backup otherwise
    this.setState({
      programs: {
        ...this.state.programs,
        attributes: {
          ...this.state.programs.attributes,
          program_includes: responseOk ? response.program_includes.phase : this.state.backupProgramIncludes,
        }
      }
    });
  }

  // Re order course contents with phases
  reorderCourseContentsWithPhases = (result: any) => {
    const { source, destination } = result;
    // Get the dragged item
    const curPhase = source.droppableId;
    const contentsOfCurPhase = [
      ...this.state.course.attributes.phases[curPhase],
    ];
    const draggedContent = contentsOfCurPhase[source.index];

    // Take a backup of contents of the cur phase to restore the re-order
    // if network call fails
    const backupContentsOfCurPhase = [...contentsOfCurPhase];
    this.setState({
      backupPhases: {
        [curPhase]: backupContentsOfCurPhase,
      },
    });

    // Re order
    contentsOfCurPhase.splice(source.index, 1);
    contentsOfCurPhase.splice(destination.index, 0, draggedContent);

    // Update UI optimisticly
    const updatedPhases = { ...this.state.course.attributes.phases };
    updatedPhases[curPhase] = contentsOfCurPhase;
    this.updatePhasesToState(updatedPhases);

    // Update reorder on the server
    const data = {
      data: [
        {
          id: backupContentsOfCurPhase[destination.index].id,
          type: backupContentsOfCurPhase[destination.index].type,
          sequence: backupContentsOfCurPhase[destination.index].sequence_number,
        },
        {
          id: backupContentsOfCurPhase[source.index].id,
          type: backupContentsOfCurPhase[source.index].type,
          sequence: backupContentsOfCurPhase[source.index].sequence_number,
        },
      ],
    };

    this.reorderPhaseContents(data);
  };

  // Re order course contents without phases
  reorderCourseContentsWithoutPhases = (result: any) => {
    const { source, destination } = result;
    const draggedContentIndex = source.index;
    // @ts-ignore
    const currentVideos = structuredClone(this.state.course.attributes.videos);
    const draggedContent = currentVideos[draggedContentIndex];

    // Take a backup of contents to restore the re-order
    // if network call fails
    const backupVidoes = this.state.course.attributes.videos;
    this.setState({ backupVidoes });

    // Re order
    currentVideos.splice(draggedContentIndex, 1);
    currentVideos.splice(destination.index, 0, draggedContent)

    // Update reorder on the server
    const payload = {
      data: [
        {
          id: backupVidoes[destination.index]?.id,
          type: backupVidoes[destination.index]?.type,
          sequence: backupVidoes[destination.index]?.sequence_number
        },
        {
          id: backupVidoes[source.index]?.id,
          type: backupVidoes[source.index]?.type,
          sequence: backupVidoes[source.index]?.sequence_number
        }
      ]
    }

    // Call api for the updated sequence
    this.reorderPhaseContents(payload);
  }

  onDragStart = (start: any) => {
    this.setState({
      droppableId: start.source.droppableId
    });
  }

  // Method to run after a drag end in build program
  onDragEndProgram = (result: any) => {
    const { source, destination } = result;
    // Handle drop outside of droppable
    if (!destination) {
      return;
    }
    // Handle drop to the initial position
    if (destination.index === source.index) {
      return;
    }

    const phaseExists = this.state.programs.attributes.program_includes.length === 0;
    phaseExists
      ? this.reorderProgramContentsWithPhases(result)
      : this.reorderProgramContentsWithoutPhases(result);
  }

  // Re order program contents with phases
  reorderProgramContentsWithPhases = (result: any) => {
    const { source, destination } = result;
    // Get the dragged item
    const curPhase = source.droppableId;
    const contentsOfCurPhase = [
      ...this.state.programs.attributes.phases[curPhase],
    ];
    const draggedContent = contentsOfCurPhase[source.index];

    // Take a backup of contents of the cur phase to restore the re-order
    // if network call fails
    const backupContentsOfCurPhase = [...contentsOfCurPhase];
    this.setState({
      backupPhases: {
        [curPhase]: backupContentsOfCurPhase,
      },
    });

    // Re order
    contentsOfCurPhase.splice(source.index, 1);
    contentsOfCurPhase.splice(destination.index, 0, draggedContent);

    // Update UI optimisticly
    const updatedPhases = { ...this.state.programs.attributes.phases };
    updatedPhases[curPhase] = contentsOfCurPhase;
    this.updateProgramPhasesToState(updatedPhases);

    // Update reorder on the server
    const payload = {
      data: [
        {
          id: backupContentsOfCurPhase[destination.index].id,
          type: backupContentsOfCurPhase[destination.index].type,
          sequence: backupContentsOfCurPhase[destination.index].sequence_number,
        },
        {
          id: backupContentsOfCurPhase[source.index].id,
          type: backupContentsOfCurPhase[source.index].type,
          sequence: backupContentsOfCurPhase[source.index].sequence_number,
        },
      ],
    };

    // Call api for the updated sequence
    this.reorderProgramContents(payload);
  }

  // Re order program contents without phases
  reorderProgramContentsWithoutPhases = (result: any) => {
    const { source, destination } = result;
    const draggedContentIndex = source.index;
    // @ts-ignore
    const currentProgramIncludes = structuredClone(this.state.programs.attributes.program_includes);
    const draggedContent = currentProgramIncludes[draggedContentIndex];

    // Take a backup of contents to restore the re-order
    // if network call fails
    const backupProgramIncludes = this.state.programs.attributes.program_includes;
    this.setState({ backupProgramIncludes });

    // Re order
    currentProgramIncludes.splice(draggedContentIndex, 1);
    currentProgramIncludes.splice(destination.index, 0, draggedContent)

    // Update reorder on the server
    const payload = {
      data: [
        {
          id: backupProgramIncludes[destination.index].id,
          type: backupProgramIncludes[destination.index].type,
          sequence: backupProgramIncludes[destination.index].sequence_number
        },
        {
          id: backupProgramIncludes[source.index].id,
          type: backupProgramIncludes[source.index].type,
          sequence: backupProgramIncludes[source.index].sequence_number
        }
      ]
    }

    // Call api for the updated sequence
    this.reorderProgramContents(payload);
  }

  getContentRole({ course_id, search, per, page }: any) {
    this.doGetContentRole({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getContentRoleListApiEndPoint + `?course_id=${course_id}&search=${search}&per=${per}&page=${page}`,
    });
  }

  cancelPhaseDeleteDialog=()=>{
    this.setState({openPhaseDelete:!this.state.openPhaseDelete,phaseName:"",phaseID:""})
  }

  cancelLessonDeleteDialog=()=>{
    this.setState({openLessonDelete:!this.state.openLessonDelete,lessonID:"",lessonName:""})
  }

  handlePhaseDeleteDialog=(phase_id:any,phase_name:any)=>{
    this.setState({openPhaseDelete:!this.state.openPhaseDelete,phaseName:`Phase${phase_name+1}`,phaseID:phase_id})
  }
  
  handleLessonDeleteDialog=(lesson_name:any,lesson_id:any)=>{
    this.setState({openLessonDelete:!this.state.openLessonDelete,lessonID:lesson_id,lessonName:lesson_name})
  }

  handleThumbnailChange = (imageData:any) => {
    this.setState({ thumbnail: imageData,formDirty:true });
  };
  handleLaunchDate = (value:any) => {
    this.setState({ launchDate: value,formDirty:true });
  };

  handleDueDate = (value:any) => {
    this.setState({ dueDate: value,formDirty:true });
  };

  handleTitle = (value: string) => {
    this.setState({ createNewTitle: value,formDirty:true });
  }
  doGetContentRole(data: any) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getContentRoleListApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  createFolder() {

    let data = new FormData()
    data.append("name", this.state.createFolder)
    data.append("folder_type", "icon");

    const header = {
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.createFolderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createFolderApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return requestMessage.messageId;

  }

  DeleteVideo(id: number, status: any) {
    const header = {
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.getDeleteVideoApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDeleteVideosApiEndPoint + `/${id}`
    );

    status !== "" && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      status
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      status === "" ? 'DELETE' : 'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return requestMessage.messageId;

  }

  DeleteQuiz(id: number, status: any) {
    const header = {
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.getDeleteQuizApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDeleteQuizApiEndPoint + `/${id}`
    );

    status !== "" && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      status
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      status === "" ? 'DELETE' : 'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return requestMessage.messageId;

  }

  DeleteObject(id: number, status: any) {
    const header = {
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.getDeleteObjectApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getdeleteObjectApiEndPoint + `/${id}`
    );

    status !== "" && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      status
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      status === "" ? 'DELETE' : 'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return requestMessage.messageId;
  }

  DeleteCourse(id: number, status: any) {
    const header = {
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.getDeleteCourseApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getdeleteCourseApiEndPoint + `/${id}`
    );

    status !== "" && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      status
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      status === "" ? 'DELETE' : 'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return requestMessage.messageId;

  }

  getFolderData() {


    const header = {
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.getFolderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFolderApiEndPoint + `?folder_type=icon`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Get'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return requestMessage.messageId;

  }

  handleCreateFolderClose = () => {
    this.setState({ folderModalOpen: false })
  }

  handleCreateFolderOpen = () => {
    this.setState({ folderModalOpen: true })
  }

  // Get SAS token
  getSASToken = async () => {
    this.getSASTokenApiCallId = await apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getSASTokenApiEndPoint,
    });
  }

  // Handle get sas token response
  handleGetSASTokenResponse = async (response: any) => {
    if (!response.data) {
      this.setState({ isLoading: false });
      toast.error("Something went wrong");
    }
    const sasUrl = response.data;
    const containerName = response.container_name;
    let fileName = this.state.file.name;

    // Get the video duration & video key
    const videoDuration = await this.getVideoDuration(this.state.file);
    const videoKey = `${response.container_name}/${response.container_name}/${fileName}`;

    // Append video duration & key to the form data
    this.formData.append("program_object[video_key]", videoKey);
    this.formData.append("program_object[video_size]", videoDuration);

    // Create object
    this.doCreateObject({
      contentType: configJSON.validationApiContentType,
      method: configJSON.PostMethod,
      endPoint: configJSON.createObjectApiEndPoint,
      body: this.formData,
    });
  }


createCourseResponsePopup=(responseJson: any): CoursePopupData[] =>{

  type AttributesArray = CourseAttributes[];
  const attributesArray: AttributesArray = responseJson.data.map((item: any) => item.attributes);
  
  const courseResponsePopup: CoursePopupData[] = attributesArray.map((attributes: CourseAttributes) => ({
    title: attributes.title || '',
    course_id: attributes.id.toString() || '',
    xpert_name: attributes.expert_name || '',
    user_enrolled: attributes.user_enrolled || 0,
    status: attributes.status || '',
    updated_at: attributes.updated_at || '',
    number: attributes.number || '',
    content_roles: attributes.content_roles || "--",
    total_chapters: attributes.total_chapters || 0,
    thumbnail_image: {
      id: attributes.thumbnail_image?.id || null,
      url: attributes.thumbnail_image?.url || '',
    },
  }));

  return courseResponsePopup;

}

handleCourseDataResponse=(responseJson: CourseResponseJson)=> {
  if (responseJson && responseJson.data) {
    const courseResponsePopup: CoursePopupData[] = this.createCourseResponsePopup(responseJson);
    let filteredCourseAddedResponse = courseResponsePopup.filter((course) => 
      !this.state.courseAdded.some(addedCourse => addedCourse.id === course.course_id)
    );

    this.setState({ courseResponse: filteredCourseAddedResponse, initialCourseResp: courseResponsePopup});
  } else 
    console.error('3458', responseJson.data);
}

  // Get the duration of a video file
  getVideoDuration = (file: File) => {
    const video = document.createElement("video");
    video.preload = "metadata";

    const onLoadedMetaDataPromise = new Promise((resolve, reject) => {
      video.onloadedmetadata = () => {
        resolve(video.duration);
        window.URL.revokeObjectURL(video.src);
        video.remove();
      };

      video.onerror = () => {
        reject(new Error("Unsupported video format. Please select a file of a different format"));
        window.URL.revokeObjectURL(video.src);
        video.remove();
      };
    });

    video.src = window.URL.createObjectURL(file);

    return onLoadedMetaDataPromise;
  };

  handleCheckboxChange = (id: string, name: string, status: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    this.setState((prevState) => {
      let selectedRows = [...prevState.selectedRows];
      let selectedCourseRows = [...prevState.selectedCourseRows];
  
      if (isChecked) {
        // Add the row if it is checked
        selectedRows.push(id);
        selectedCourseRows.push({ id, name, status });
      } else {
        // Remove the row if it is unchecked
        selectedRows = selectedRows.filter((rowId) => rowId !== id);
        selectedCourseRows = selectedCourseRows.filter((row) => row.name !== name);
      }
  
      return { selectedRows, selectedCourseRows };
    });
  };
  
  
   formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
  
    return `${month}-${day}-${year}`;
  };
  
  handleFilterDrop = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({
      filterItems: !this.state.filterItems,
      anchorFilterContainerEl: event.currentTarget,
    })
  }

  handleListItemClickSuggestion = (item: string) => {
    this.setState({
      searchtext: item,
      showList: false
    }, () => {
      this.getCourse({
        search: this.state.searchtext,
        filter: "course",
        page: "",
        per: "",
      }, 1)
    })
  }

  handleInputProgramChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    if (value === "") {
      this.getCourse({
        search: "",
        filter: this.state.isCourseActive,
        page: "",
        per: "",
      }, 1)
    }
    this.setState({
      searchtext: value,
      showList: value !== "",
    }, () => {
      if (value !== "") {
        this.getProgramAdvanceSearch(value);
      }
    });
  }

  getProgramAdvanceSearch = (value: string) => {
    this.doGetProgramAdvanceSearch({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.catagoryAdvanceSearch + `?search=${value}&type=${"course"}`,
    });
  }

  doGetProgramAdvanceSearch(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCatalogueAdvanceSearchApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  // Handle file selection in add object page
  handleImageChange = async (e: any) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      return;
    }
    let fileName = "";
    fileName += selectedFile.name + "\n";
    this.setState({
      filename: fileName,
      image: URL.createObjectURL(selectedFile),
      imgUrl: "",
      file: selectedFile,
      id: "",
    });

    // Check if we can calculate the duration of the selected video file
    const fileTypeVideo = selectedFile.type.startsWith("video");
    if (fileTypeVideo) {
      try {
        // This method throws error if it can't be loaded on browser
        await this.getVideoDuration(selectedFile);
      } catch (error: any) {
        // Show error message to user as the selected file format can't be uploaded
        this.setState({ file: "", filename: "", image: "" });
        toast.warn(error.message);
      }
    }
  }

  handleMouseEnter = (hoverId: string) => {
    this.setState({
      hoverLessonId: hoverId,
      hoverPhaseId:""
    })
  };

  handleMouseLeave = () => {
    this.setState({
      hoverLessonId: "",
    })
  };

  handleDragEnter = (hoverId: string) => {
    this.setState({
      isHovering: hoverId,
    })
  };

  handleDragLeave = () => {
    this.setState({
      isHovering: null,
    })
  };

  handlePhaseMouseEnter = (hoverId: string) => {
    this.setState({
      hoverPhaseId: hoverId,
      isHovering:hoverId,
      hoverLessonId:""
    })
  };

  handlePhaseMouseLeave = () => {
    this.setState({
      hoverPhaseId: "",
    })
  };

  handleAddIconClick = (clickedValue: boolean,previousContentId:any, phaseId:any) => {
   this.handleAddIconOpenLessonNew(previousContentId, phaseId)
  };

  handleSaveDraftCourse=(status:any)=>{
    this.setState({
      createCourseModalDraft: true,
      
    });
    const payload = new FormData();
    payload.append("course[status]", status);
    this.saveCreateCourse({
      contentType: configJSON.validationApiContentType,
      method: configJSON.PutMethod,
      endPoint: configJSON.updateCourseAPiEndPoint + this.state.createdCourseID,
      body: payload
    });
  }

  saveCreateCourse(data: any) {
    const { method, endPoint, body } = data;
    const header = {
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getSaveCourseApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleAddPhase = (id: any) => {
    let data={
      "phases": {
        "phaseable_id": Number(id),
        "phaseable_type": "BxBlockCoursecreation::Course"
      }
    }
    this.addPhase({
      contentType: configJSON.validationApiContentType,
      method: configJSON.PostMethod,
      endPoint: configJSON.addPhaseApiEndPoint,
      body: JSON.stringify(data),
    });
  };

  gotoLessons(type:any) {
    this.props.navigation.navigate("Customform",{
      type:"addLesson",
      id: this.state.phaseID,
      courseID: this.state.createdCourseID,
      contentType: type,
      parentType: this.state.phasesIncluded ? "Phases" : "AddPhases",
      previousLessonId: this.state.previousLessonId,
      lessonEditId:"new"
      });
  }

  handleLessonEdit=(id:string,type:string,previousLessonId:string)=>{

    this.props.navigation.navigate("Customform", {
    type: "editLesson", 
    id: this.state.phaseID,
    courseID: this.state.createdCourseID,
    contentType: type,
    parentType: this.state.phasesIncluded ? "Phases" : "AddPhases",
    previousLessonId: previousLessonId,
    lessonEditId:id
  });
  }

  handlePhaseAddIconClick = (id: any,previousID:any) => {
    let data={
      "phases": {
        "phaseable_id": Number(id),
        "phaseable_type": "BxBlockCoursecreation::Course",
        "previous_phase_id": previousID
      }
    }

    this.addPhase({
      contentType: configJSON.validationApiContentType,
      method: configJSON.PostMethod,
      endPoint: configJSON.addPhaseApiEndPoint,
      body: JSON.stringify(data),
    });
  };

  showEmptyText=(content:any,index:any)=>{
    return content.attributes.content.length == 0 && this.state.showPhassesDropDown !== index
  }

  renderName=(name:any,dataName:any)=>{
    return name ? "Add a name" : dataName
  }

  addPhase(data: any) {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.addPhaseApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleChangePhaseOrder = (id: any,previousID:any) => {
    let data={
      "phase_id": Number(id),
      "position": previousID
    }
    this.changePhaseOrder({
      contentType: configJSON.validationApiContentType,
      method: configJSON.PostMethod,
      endPoint: configJSON.changePhasesOrderApiEndPoint,
      body: JSON.stringify(data),
    });
  };

  changePhaseOrder(data: any) {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.changePhaseOrderApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getTagsListData() {
    this.doTagList({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: "",
    });
  }
  doTagList = async (data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) => {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
    };
    const requestMessageData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessageData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getApiCallId = requestMessageData.messageId
    requestMessageData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessageData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessageData.id, requestMessageData);
    return requestMessageData.messageId;
  }


  handleSaveDraftModal = () => {
    this.setState({
      modalOpen: true
    })
  };

  handleModalClose = () => {
    this.setState({
      modalOpen: false
    })
  };

  handleDropDown = (index: string) => {
    this.setState({
      showPhassesDropDown: index
    });
  }

  handleMouseEnterPhases = (index: string) => {
    this.setState({
      showPhasesDialogLesson: index
    })
  }

  getCategoryListData() {
    this.doCategoryList({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: "",
    });
  }
  doCategoryList = async (data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) => {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
    };
    const requestMessageResponse = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessageResponse.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getApiCallId = requestMessageResponse.messageId
    requestMessageResponse.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessageResponse.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessageResponse.id, requestMessageResponse);
    return requestMessageResponse.messageId;
  }

  handleMouseLeavePhase = () => {
    this.setState({
      showPhasesDialogLesson: ''
    })
  }

  handleEnterPhaseRename = (index: string) => {
    this.setState({
      showRenameDialog: index
    })
  }

  handleLeavePhaseRename= () => {
    this.setState({
      showRenameDialog: ''
    })
  }

  onDragEnd = (result: any) => {
    const { destination, source, type } = result;

    if (!destination) {
      return; // No destination means the item was dropped outside a droppable area.
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return; // Item was dropped in its original position.
    }

    let contents = [...this.state.phasesContent]; // Create a shallow copy of phasesContent
    let referenceId = '';

    if (type === "phase") {

      this.typePhaseCondition(destination,referenceId,source,contents)

    } else if (type === "content") {
      const startPhase = contents.find(phase => `content-${phase.id}` === source.droppableId);
      const finishPhase = contents.find(phase => `content-${phase.id}` === destination.droppableId);

      if (!startPhase || !finishPhase) return;

      const startContent = [...startPhase.attributes.content];
      const finishContent = [...finishPhase.attributes.content];
      const destinationIndex = destination.index;
      const sourceIndex = source.index;
      const draggedId = startContent[sourceIndex].id;

      this.typeContentCondition(destinationIndex,finishContent,referenceId,sourceIndex,draggedId,contents)
      const [removed] = startContent.splice(sourceIndex, 1);
      if (startPhase === finishPhase) {
        startContent.splice(destinationIndex, 0, removed);
        startPhase.attributes.content = startContent;
      } else {
        finishContent.splice(destinationIndex, 0, removed);
        finishPhase.attributes.content = finishContent;
      }

      this.setState({ phasesContent: contents });
    }
  };

  typePhaseCondition=(destination:any,referenceId:any,source:any,contents:any)=>{
    const destinationIndex = destination.index;
    const sourceIndex = source.index;
    const draggedId = contents[sourceIndex].id;
    if (destinationIndex === 0) {
      referenceId = "first";
    } else if (destinationIndex === contents.length - 1) {
      referenceId = "last";
    } else {
      referenceId = contents[destinationIndex > sourceIndex ? destinationIndex + 1 : destinationIndex].id;
    }
    this.handleChangePhaseOrder(draggedId, referenceId);
    const [removed] = contents.splice(sourceIndex, 1);
    contents.splice(destinationIndex, 0, removed);

    this.setState({ phasesContent: contents });
  }

  typeContentCondition=(destinationIndex:any,finishContent:any,referenceId:any,sourceIndex:any,draggedId:any,contents:any)=>{
    if (destinationIndex === 0) {
      referenceId = "first";
    } else if (destinationIndex === finishContent.length - 1) {
      referenceId = "last";
    } else {
      referenceId = finishContent[destinationIndex > sourceIndex ? destinationIndex + 1 : destinationIndex].id;
    }

    this.handleDragEndLessonAction(draggedId,referenceId,"lesson","lesson");
  }

  onDragEndLesson = (result:any) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    const contentLessons = this.state.content_value;
    const destinationIndex = destination.index;
    const sourceIndex = source.index;
    const draggedId = contentLessons[sourceIndex].id

    if (destinationIndex === sourceIndex) {
      return;
    }

    let referenceId;

    if (destinationIndex === 0) {
      referenceId = "first";
    } else if (destinationIndex === contentLessons.length - 1) {
      referenceId = "last";
    } else {
      referenceId = contentLessons[destinationIndex > sourceIndex ? destinationIndex + 1 : destinationIndex].id;
    }
    this.handleDragEndLessonAction(draggedId,referenceId,"lesson","lesson");

    this.setState(prevState => {
      const newContents = Array.from(prevState.content_value);
      const [removed] = newContents.splice(source.index, 1);
      newContents.splice(destination.index, 0, removed);

      return { content_value: newContents };
    });
  }

  handleDragEndLessonAction=(lesson_id:number,position:any,lesson_type:string,position_type:string)=>{
    let data={
      "lesson_id": lesson_id,
      "lesson_type": lesson_type,
      "position":position,
      "position_type": position_type
    }
    this.changeLessonOrder({
      contentType: configJSON.validationApiContentType,
      method: configJSON.PostMethod,
      endPoint: configJSON.changeLessonsOrderApiEndPoint,
      body: JSON.stringify(data),
    });
  }

  changeLessonOrder(data: any) {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.changeLessonOrderApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleCloseLessson=()=>{
    this.setState({openLesson:false})
  }

  handleOpenLessonNew = () => {
    this.setState({
      openLesson: true,
    })
  }
  handleAddIconOpenLessonNew = (previousLessonId?:string,id?:string) => {
    this.setState({
      openLesson: true,
      previousLessonId:previousLessonId?previousLessonId:"",
      phaseID:id
    })
  }
  

  renderDaily = (isEnabled: boolean, currentValue: string) => {
    if (isEnabled) {
      if (currentValue === 'daily')
        return "calender-btnoutlined"
      else
        return "calender-btn"

    } else {
      return 'calender-btndisabled'
    }
  }

  renderWeekly = (isEnabled: boolean, currentValue: string) => {
    if (isEnabled) {
      if (currentValue === 'weekly')
        return "calender-btnoutlined"
      else
        return "calender-btn"

    } else {
      return 'calender-btndisabled'
    }
  }

  renderMonthly = (isEnabled: boolean, currentValue: string) => {
    if (isEnabled) {
      if (currentValue === 'monthly')
        return "calender-btnoutlined"
      else
        return "calender-btn"

    } else {
      return 'calender-btndisabled'
    }
  }

  getInitialValue() {
    const { programs = {} } = this.state;
    const { attributes = {} } = programs;
    const id=this.props.navigation.getParam('id')
  if(!!attributes && id!=='new'){
    const selectedCategories = attributes.categories
    ? attributes.categories.map((category: ProgramCategoryType) => ({
        id: category.id,
        type: "category",
        // name: category.name, // Include any other necessary category fields
      }))
    : [];
    return {
      title:   attributes.title ,
      description: attributes.description,
      is_sellable: attributes.is_sellable,
      price: attributes.price,
      is_forced_learning: attributes.is_forced_learning ,
      // keep_in_draft_stats: attributes.keep_in_draft_stats,
      is_notify_when_completed: attributes?.is_notify_when_completed ,
      time_constraint: attributes.time_constraint,
      part_of_program: attributes.part_of_program,
      include_launch_date: attributes.include_launch_date,
      launch_date: attributes.launch_date ? new Date(attributes.launch_date) : null,
      due_date: attributes.due_date ? new Date(attributes.due_date) : null,
      content_release_schedule: attributes.content_release_schedule,
      content_release_schedule_value: attributes.content_release_schedule_value,
      add_thumbnail: attributes.thumbnail_image?.url,
      add_due_date: attributes.add_due_date,
      add_to_xpand_collection: attributes.add_to_xpand_collection,
      is_trial: false,
      subscription_period: attributes.subscription_period,
      dueDateToggle: false,
      days_to_complete_the_program: attributes.days_to_complete_the_program,
      phases: 100,
      is_free_trial: attributes.is_free_trial,
      isEdit: false,
      program_id: 0,
      start_date:new Date(attributes.launch_date),
      end_date: new Date(attributes.due_date),
      notify_when_completed: attributes.notify_when_completed,
      program_timeline: attributes.course_timeline,
      expert_id: attributes.expert_id,
      selectedCategories,
      ...this.props?.location?.state,
    };
  }else{
    return {
      title:"",
      expert_id: this.state.programSelectedExpert?.id,
      description: "",
      selectedCategories:[],
      include_launch_date: false,
      launch_date: null,

      add_due_date: false,
      due_date: null,

      days_to_complete_the_program: false,
      program_timeline:  "",

      is_notify_when_completed: false,
      notify_when_completed:[],

      content_release_schedule:false,
      content_release_schedule_value: "",
      phases:"",

      is_forced_learning: false,
      // keep_in_draft_stats: false,

      is_sellable:false,
      price: "",
      subscription_period: "",
    };
  } 
}
  
  onSelectExpert(expert: ExpertType|null) {
    this.setState({ programSelectedExpert: expert })
  }

  handleProgramLaunchDateChange = (value:Date) => {
    this.setState({ programLaunchDate: value });
  };

  handleProgramDueDateChange = (value:Date) => {
    this.setState({ programDueDate: value });
  };

  getExpertSearchListNew = () => {
    const token = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.expertApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_library2/experts"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async handleExpertResponse(experts: Array<ExpertType>) {
    this.setState({ programAllExperts: experts})
  }

  programValidationSchema = yup.object().shape({
    title: yup
      .string()
      .required("Title is required")
      // .min(5, "min 5 characters required")
      // .max(50, "max 50 characters required"),
})

handleOpenLessson=(id:any)=>{
  this.setState({openLesson:true,phaseID:id})
}

handleLessonOpen = (id: number) => {
  this.setState({
    openLesson: true,
    phaseID:id
  })
}

getDueDateMinimumValue = (launchDate: Date) => {    
                                                         
  if(!!launchDate){
    return addDays(launchDate,1)
  }else{
    return addDays(startOfToday(),2)
  }
}


programSelectCategoryFnOne = (index: number, categories: Array<ProgramCategoryType>) => {
    let items = [...categories];
    let item = { ...items[index] };
    item.selected = !item.selected;
    items[index] = item;
    this.setState({ programCategory: [...items]});
    return items.filter((item)=> item.selected===true)
  };

  handleDay(input:string, current:string){
    const regex = /^\d+$/;
    if (input === "") {
      return "";
    }
    if (!regex.test(input)) {
      return current;
    }
  return input
}
getDueDateEnable(isEnabled:boolean){
  if(isEnabled){
    return ""
  }else{
    return 1
  }
}

handleToastMsg = (message:string) => {
  this.setState({
    toastMsg: message,formDirty:true
  });
};

getPaddingRight = (): string => {
  // Use optional chaining to safely access isEditMode
  return this.state?.isEditMode ? "36px" : "0px";
};

doAdminCategoryListing(data: {
  contentType?: string;
  endPoint?: string;
  method?: string;
  body?: {};
  type?: string;
}) {
  const { contentType, method, endPoint } = data;
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)

  );
  const header = {
    "Content-Type": contentType,
    token: localStorage.getItem("token"),
  };
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  this.adminCatagoryListingAPICallId = requestMessage.messageId
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
}
getAdminCategoryListing() {
  this.doAdminCategoryListing({
    contentType: configJSON.validationApiContentType,
    method: configJSON.apiMethodTypeGet,
    endPoint: configJSON.catagoryListingApiEndPoint + `?search=${this.state.categorySearchText}`,
  });
}
handleClose = () => {
  this.setState({
    createCourseModal: false,
    createCourseModalDraft: false
  }, () => {
    this.goToContentManagement();
  });
};
handlePopupClose = () => {
  this.setState({
    showCoursePopup: false,
    selectedRows: [],
    searchtext: "",
  })
};
handlePopupBackClose = () => {
  this.setState({
    showCoursePopup: false,
  })
};

handleSelectCourse = () => {

  const { selectedRows, courseResponse, selectedCourseRows } = this.state;
  let filteredCourseAddedResponse = courseResponse.filter((course) => selectedRows.includes(course.course_id));
  let filteredCourseResponse = courseResponse.filter((course) => !selectedRows.includes(course.course_id));
 this.setState(prevState => ({
  showCoursePopup: false,
  coursesSelected: true,
  courseResponse: filteredCourseResponse,
  courseAddedResponse: filteredCourseAddedResponse,
  courseAdded: selectedCourseRows,
  searchtext: "",
}));

};


handleCloseOne = () => {
  setStorageData("isSaveDraft", "false")
  this.setState({
    createCourseModal:false,
    createCourseModalDraft: false
  }, () => {
    this.goToContentManagement()
  });
};

handleToastSavedDraft = () => {
    toast.success(`${this.state.courseTitle} saved as draft.`)
};

handleToastSavedActive = () => {
    toast.success(`${this.state.courseTitle} saved and now active.`)
};

goToContentManagement = () => {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage),"ContentManagement");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message)
};

sendActiveStatus = () => {
  this.setState({
        createCourseModal: true,
  });
};

sendDraftStatus = () => {
  this.setState({
        createCourseModalDraft: true,
  });
};

handleCrossIconClose = () => {
  this.setState({
    createCourseModal: false,
    createCourseModalDraft: false
  });
};
handleThumbnailShow=()=>{
  this.setState({showThumbnailPopup:true})
}
handleThumbnailModalClose=(objectData:any)=>{
  this.formikRef?.setFieldValue("add_thumbnail",objectData.attributes.image.url)
this.setState({showThumbnailPopup:false,thumbnailId:objectData.id,programThumbnailImage:objectData.attributes?.image.url})
}
closeModal=()=>{
  this.setState({showThumbnailPopup:false})
}
dropdownHandlerFilterOpen = (event: React.MouseEvent<HTMLDivElement>, dropdownType: string) => {
  this.setState({
    anchorElPopup: event.currentTarget,
    dropdownType
  })
}

dropdownHandlerOpen = (event: React.MouseEvent<HTMLDivElement>) => {
  this.setState({
    anchorEl: true,
  })
}

backColor = (value: any) => {
  return value ? "#FCF6F6" : "#E5E2E1"
}
TextColor=(value: any)=>{
  return value?"#ADAAAA":"#787776"
}
dropdownHandlerClose = () => {
  this.setState({
    anchorElPopup: null,
    dropdownType: "",
    isCalendarOpen: false,
  })
}

placeholderCheckboxChangeHandler = (event:any, optionId:any) => {
  const { checked } = event.target;
  if(checked){
    this.formikRef?.setFieldValue("expert_id", optionId);

    this.setState({
      anchorEl:false,
      programSelectedExpert:optionId
    })
  }else{
    this.formikRef?.setFieldValue("expert_id", "");
    this.setState({
      anchorEl:false,
      programSelectedExpert:optionId
    })
  }
};
handlePlaceholderSearchChange=(event:any)=>{
this.setState({placeholderSearchText:event.target.value})
}
showAlert = () => {
  this.setState({
    createCourseModal:false,
    createCourseModalDraft: false
  }, () =>{
    toast.error("Each phase should have atleast one lesson");
  })
};

handlePhaseCancel = () => {
  this.setState({
    RemovePhase: false,  
    selectedCourseID: "", 
  });
};

handleRemovePhase = () => {
  const { selectedCourseRows, selectedCourseID, courseAddedResponse } = this.state;
  
  // Filter out the linked items and the unlinked item
  const linkedItems = courseAddedResponse.filter((row) => row.title !== selectedCourseID);
  const unlinkedItem = courseAddedResponse.find((row) => row.course_id === selectedCourseID);

  const updatedSelectedCourseRows = selectedCourseRows.filter(row => row.id !== selectedCourseID);

  // Determine if any courses are selected
  const coursesSelected = updatedSelectedCourseRows.length === 0 ? false : true;
  let newArray = [...this.state.courseResponse];
if (unlinkedItem) {
  newArray.push(unlinkedItem);
}
  // Update state
  this.setState ({
    courseAdded: updatedSelectedCourseRows,
    RemovePhase: false,
   selectedCourseName: "",
    coursesSelected: coursesSelected,
    selectedCourseRows: updatedSelectedCourseRows,
    courseResponse: newArray,
    courseAddedResponse: linkedItems,
  });

};


handleDetach = (courseID: string) => {
  // Find the course to remove
  const courseToRemove = this.state.selectedCourseRows.find(course => course.id === courseID);
  // If no course was found, return early
  if (!courseToRemove) {
    return null;
  }

  // Set the state to show the modal and update selected rows
  this.setState(prevState => ({
    courseToRemove: [courseToRemove], // Store the course to be removed in an array as per your type
    RemovePhase: true,  // Show the DeleteModal
    selectedCourseID: courseID,
    selectedCourseName: courseToRemove.name,
    selectedRows: prevState.selectedRows.filter(id => id !== courseToRemove.id) // Filter out the removed course
  }));

};


handleSort = (key: keyof CoursePopupData) => {
  const { courseResponse, sortConfig } = this.state;
  let direction: 'asc' | 'desc' = 'asc';

  if (sortConfig.key === key && sortConfig.direction === 'asc') {
    direction = 'desc';
  }

  const sortedData = [...courseResponse].sort((a, b) => {
    if (key === 'updated_at') {
      const dateA = new Date(a[key]);
      const dateB = new Date(b[key]);
      return direction === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
    } else if (typeof a[key] === 'string') {
      return direction === 'asc'
        ? (a[key] as string).localeCompare(b[key] as string)
        : (b[key] as string).localeCompare(a[key] as string);
    } else {
      return direction === 'asc'
        ? (a[key] as number) - (b[key] as number)
        : (b[key] as number) - (a[key] as number);
    }
  });

  this.setState({
    courseResponse: sortedData,
    sortConfig: { key, direction },
  });
};
getDiscardApi=()=>{
  const header = {
    token: localStorage.getItem("token"),
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)

  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  this.getDiscardApiCallId = requestMessage.messageId
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.DiscardChangesApiEndPoint}/${this.state.createdCourseID}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;

}
getDiscardApiSuccess=(responseJSON:any)=>{
  if(responseJSON.message){
    toast.success(responseJSON.message,{autoClose:2000})
    this.handleDiscardModalClose()
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ContentManagement");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
}
handleSaveChanges=()=>{
    toast.success("Course updated successfully",{autoClose:2000})
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ContentManagement");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
}
handleBackProduct=()=>{
 this.setState({showDiscardModal:true})
}
handleDiscardModalClose=()=>{
  this.setState({showDiscardModal:false})
}

  // Customizable Area End
}