// Customizable Area Start
import React from "react";
import {
  Grid,
  Button,
  styled,
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TableCell,
  TableBody,
  Popover,
  Badge,
  Typography,
} from "@material-ui/core";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import "./styles.css";
import { DownArrowIcon, EditIcon2, Filter, UpArrowIcon, plusIcon, thumbnailImg } from "./assets";
import UserManagementController, { UserResponse } from "./UserManagementController.web";
import Loader from "../../../components/src/Loader";
import { NewFilter } from "../../../../packages/blocks/catalogue/src/assets";
import AdvancedSearch from "../../advancedsearch/src/AdvancedSearch.web";
import FilterItems from "../../filteritems/src/FilterItems.web"
import moment from "moment";
export interface Props {
  classes: any;
}
export interface State {
  columnData: any;
  filter: string;
}
class UserManagement extends UserManagementController {
  render() {
    const { classes } = this.props;
    const { anchorUserFilterContentsEl } = this.state;
    const openFilterContainer = Boolean(anchorUserFilterContentsEl)
    const userManagementControlsContent = (
      <Grid spacing={2} className={this.props.classes.controlsContainer}>
          <ToggleBox >
            <ToggleInnerbox  className={this.props.classes.courseToggleStyle} >
              <div className={classes.primaryBranch}>
              Primary Branch
              </div>
              <div className={classes.D2DU}>
                D2DU
              </div>
            </ToggleInnerbox>
          </ToggleBox>
            <StyledContentWrapper>
              <AdvancedSearch
              data-test-id="advanced_btn"
              searchInputValue={this.state.searchAdvanceValues}
              handleInputChange={this.handleInputChangeSearch}
              handleListItemClick={this.handleThumbnailListClick}
              filterList={this.state.filteredContentsLists}
              inputWidth={window.innerWidth<500?"100%":"480"}
              isFilterDropActive={openFilterContainer}
              showList={this.state.showContentData} navigation={undefined} id={""} />
              <div style={{ marginLeft: "0px" }} className={this.state.anchorUserFilterContentsEl ? "filterIconBox allBorder" : "filterIconBox"} aria-describedby={"myPopover"} onClick={this.handleFilterDropClick}>
                {this.state.creationIconsDate.length > 0 || (this.state.creationDateParamsdatas.hasOwnProperty('start_date') && this.state.creationDateParamsdatas.start_date.length > 0) || this.state.placeholderSelectsTeamsItems.length > 0 || this.state.placeholderSelectsBranchItems.length > 0? (
                  <BadgeComponents color="secondary" variant="dot"></BadgeComponents>) : (
                  ""
                )
                }
                {this.state.anchorUserFilterContentsEl ? (
                  <img src={Filter} data-test-id="filterOpenTestID" alt="Filter" />
                ) : (
                  <img src={NewFilter} data-test-id="filterOpenTestID" />
                )}
              </div>
              <Popover 
                data-test-id="filterPropsID"
                className="filterContainerPopover"
                open={openFilterContainer}
                id="myPopover"
                anchorEl={this.state.anchorUserFilterContentsEl}
                onClose={this.filterContainerDropdownCloseHandlers}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
              
              >
                <FilterItems
              isThumbnail
              navigation={this.props.navigation}
              data-test-id="FilterItemsTestID"
              handleClick={this.handleClicksFilters}
              placeholderCheckboxUserManageChangeHandler={this.placeholderCheckboxUserManageChangeHandlers}
              placeholderCheckboxUserManageChangeHandlerBranch={this.placeholderCheckboxUserManageChangeHandlersBranch}
              handlePlaceholderUserSearchChange={this.handlePlaceholderUserTeamsSearchChanges}
              handlePlaceholderUserSearchChangeBranch={this.handlePlaceholderUserBranchSearchChanges}
              dropdownOpenHandler={this.dropdownHandlerOpens}
              creationDateChangeHandler={this.creationChangeHandlerDates}
              dropdownCloseHandler={this.dropdownHandlerClose}
              anchorEl={this.state.anchorsEl}
              dropdownType={this.state.dropdownType}
              placeholderSelectedTeamsItems={this.state.placeholderSelectsTeamsItems}
              placeholderSelectedBranchItems={this.state.placeholderSelectsBranchItems}
              placeholderSearchText={this.state.placeholderSearchsTexts}
              creationDate={this.state.creationIconsDate}
              userTeamsList={this.state.userTeamsList}
              userBranchList={this.state.userBranchList}
              isCalendarOpen={this.state.isCalendarshow}
              handleClear={this.handleClearFilters}
              placeholderSelectedItems={[]}
              placeholderSearchBranchText={this.state.placeholderSearchBranchText} 
              handleCalendarOpen={function (): void {}} 
              handleCalendarChange={function (): void {}} 
              />
              </Popover>
            </StyledContentWrapper>
            <Button 
              className="create-user-btn"
              data-test-id="createNavigationTest"
              onClick={() => this.props.navigation.navigate("CreateUser")}
              style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Poppins", backgroundColor: "#652786" }}
            >
              <span style={{ fontSize: "24px", marginRight: "4px" }}>
                <img src={plusIcon}/>
              </span>{" "}
              Create User
            </Button>
      </Grid>
    );

    let tableContentUI;
    const { UserData } = this.state;
    if (UserData.length === 0) {
      tableContentUI = (
        <  AddIconNotFoundBoxs>
        <AddIconInnerBoxs>
          <Box>
            <AddImageComponents src={thumbnailImg} />
          </Box>
          <AddIconNotFoundTexts>No results found</AddIconNotFoundTexts>
        </AddIconInnerBoxs>
      </  AddIconNotFoundBoxs>
      );
    } else {
      tableContentUI = (
        <TableContainer style={{marginTop:"2%"}}>
        <Table style={{ borderSpacing:"0px 15px",borderCollapse:"separate" }} aria-label="simple table">
          <TableHead >
            <TableRow>
            {this.state.UserTableHeaderData.map((header, index) => {
            return (
              <TableCell
              style={{borderBottom:"none", }} 
                key={index} 
              >
                <TableSortLabel 
                  direction={"asc"} 
                  className={classes.sortTableHeader}
                >
                  <span 
                    className={classes.userTableHeader}
                  >
                    {header.name}
                  </span>
                  {header.key !== "branches" && header.key !== "status" && (
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column', 
                      marginLeft: '10px'
                    }}>
                      <img
                        src={DownArrowIcon}
                        alt="Up Arrow"
                        width="10px"
                        data-test-id="testing"
                        className={classes.upArrow}
                        onClick={() => this.handleSort(header.key)}
                      />
                      <img
                        src={UpArrowIcon}
                        data-test-id="testing"
                        alt="Down Arrow"
                        width="10px"
                        className={classes.downArrow}
                        onClick={() => this.handleSort(header.key)}
                      />
                    </div>
                  )}
                </TableSortLabel>
              </TableCell>
            );
            })}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.UserData.map((row: UserResponse) => (
              <TableRow className={classes.userDataRowStyle} key={row.id}
              >
                <TableCell data-test-id="userManageClick" className={classes.UserTableCell} onClick={()=>{this.handleUserManageData(row.id)}} >
                  <p className={this.props.classes.userTextStyle}>
                    {row?.attributes?.full_name}
                    </p>
                    </TableCell>
                <TableCell data-test-id="userManageClick" style={{borderBottom:"none",padding:"0 16px",}} onClick={()=>{this.handleUserManageData(row.id)}}>
                <p className={this.props.classes.userTextStyleBranch}>
                  {row?.attributes?.branches}
                  </p>
                </TableCell>
                <TableCell data-test-id="userManageClick" style={{ width: "100px",borderBottom:"none",padding:"0 24px"}} align="center" onClick={()=>{this.handleUserManageData(row.id)}}>
                <span className={this.props.classes.userTextStyle2}>
                  {row?.attributes?.teams}
                  </span>
                  </TableCell>
                <TableCell data-test-id="userManageClick" style={{ width: "157px",borderBottom:"none",padding:"0 24px"}} onClick={()=>{this.handleUserManageData(row.id)}}>
                <span className={this.props.classes.userTextStyle}>
                  {row?.attributes?.invited_by}
                  </span>
                  </TableCell>
                <TableCell data-test-id="userManageClick" style={{width: "147px",borderBottom:"none",padding:"0 24px"}} onClick={()=>{this.handleUserManageData(row.id)}} >
                  <span className={this.props.classes.userTextStyle2}>
                  { moment.utc(row?.attributes?.created_at).format('MM-DD-YYYY')}
                  </span>
                  </TableCell>
                <TableCell data-test-id="userManageClick" style={{ width: "147px",borderBottom:"none",padding:"0 24px"}} onClick={()=>{this.handleUserManageData(row.id)}} >
                <span className={this.props.classes.userTextStyle2}>
                { moment.utc(row?.attributes?.last_login).format('MM-DD-YYYY')}
                </span>
                    </TableCell>
                <TableCell data-test-id="userManageClick" style={{ width: "96px",borderBottom:"none",paddingLeft:"0px"}} onClick={()=>{this.handleUserManageData(row.id)}}>
                <div
                    className={
                      row?.attributes?.status === "Active"
                      ? this.props.classes.statusStyle
                      : this.props.classes.statusInvitedStyle
                    } >
                      { row?.attributes?.status}
                    </div></TableCell>
                    
                    <TableCell style={{borderBottom:"none",paddingLeft:"0px", borderTopRightRadius: "12px", borderBottomRightRadius: "12px"}}>

                    <img src={EditIcon2}
                    data-test-id="handleEditUser"
                    onClick={() =>this.handleEditUserData(row.id)}
                    style={{color:"black"}} />
                    </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      );
    }

    return (
      <>
        <div className="user-management">
          {userManagementControlsContent}
          {tableContentUI}
        </div>
      </>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    controlsContainer: {
      margin: "20px 20px",
      display:"flex" , 
      alignItems:"center",
      justifyContent: "space-between",
      flexWrap:"wrap"
    },
    justifyEnd: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      [theme.breakpoints.up("lg")]: {
        justifyContent: "flex-end",
      },
    },
    paper: {
      background: "#EEEEEE",
      color: "#11142D",
      borderRadius: 18,
      marginTop: 10,
      boxShadow: "none",
      fontSize: "16px", 
      fontWeight: 500
    },
    courseToggleStyle: {
      backgroundColor: "#FFFFFF",
      height: "72px",
      width:"304px",
      boxShadow: "6px 6px 25px 0px #E5E5E580, 0px 6px 25px 0px #E5E5E540",
      display: "flex",
      alignItems: "start",
      padding:"8px",
      justifyContent: "center",
      borderRadius: "20px",
      cursor: "pointer",
    },
    statusStyle: {
      width: '96px', 
      height: '40px', 
      borderRadius: '88px', 
      backgroundColor: '#E0D4E7', 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center', 
      textAlign: 'center',  
      padding: '6px 9px', 
      gap: "10px",
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "14px",
      color: "#542070",
    },
    statusInvitedStyle: {
      width: '96px', 
      height: '40px', 
      borderRadius: '88px', 
      backgroundColor: '#FFEDE4',
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center', 
      textAlign: 'center',  
      padding: '6px 9px', 
      gap: "10px",
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "14px",
      color: "#E47004",
    },
    userTableHeader:{
      display: "flex", 
      justifyContent: "space-between", 
      whiteSpace: "nowrap", 
      fontWeight: 600, 
      fontStyle: "normal", 
      lineHeight: "24px", 
      color: "#1C1B1B",
      fontSize:"20px", 
      fontFamily: "Poppins"
    },
    primaryBranch:{
      fontWeight: 700, 
      color: "#2B2E3CCC",
      fontSize:"16px", 
      fontFamily: "Poppins",
      marginLeft:"20px"
    },
    D2DU:{
      fontWeight: 500, 
      color: "#2B2E3C99",
      fontSize:"16px", 
      fontFamily: "Poppins",
      marginLeft:"20px"
    },
    sortTableHeader:{
      display: "flex",
      flexDirection: 'row', 
      alignItems: 'start' 
    },
    upArrow:{
      paddingBottom: '3px',
      cursor: 'pointer !important'
    },
    downArrow:{
      cursor: 'pointer !important'
    },
    userDataRowStyle:{
      height:"72px",
      borderRadius: "12px !important",
      cursor: "pointer",
      padding:"8px 32px",
      "&:hover": {
        background: "#EEE8F2",
        borderRadius: "12px !important",
      },
    },
    userTextStyle:{
      width: "100px",
      fontWeight: 400, 
      color: "#1C1B1B",
      fontSize:"16px", 
      fontFamily: "Poppins",
      
    },
    userTextStyleBranch:{
      width: "200px",
      fontWeight: 400, 
      color: "#1C1B1B",
      fontSize:"16px", 
      fontFamily: "Poppins",
      whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline-block"
    },
    userTextStyle2:{
      fontWeight: 400, 
      color: "#000000",
      fontSize:"16px", 
      fontFamily: "Poppins"
    },
    UserTableCell:{
      width: "100px",
      borderBottom:"none",
      padding:"0 24px",
      "& .MuiTableCell-root": {
        borderBottom:"none",
      },
    }
  });

  const StyledContentWrapper = styled(Box)({
    position: "relative",
    display: "flex",
    maxWidth: "520px",
    width: "100%",
    "@media (max-width: 1000px)": {
     margin:"20px 0px 15px 0px !important"
    },
    "& .filterIconBox": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#FAFAFA",
      width: "63px",
      borderRadius: "8px",
      borderTopLeftRadius: "8px !important",
      borderBottomLeftRadius: "8px !important",
      cursor: "pointer",
      border: "1px solid #C9C6C5",
      position: "relative",
      zIndex: 999,
      left: "-1%"
    },
    "& .filterIconBox:hover": {
      borderColor: '#652786'
    },
    "& .allBorder": {
      borderColor: '#652786',
      backgroundColor: "#EEE8F2"
    }
  });

  const ToggleBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-around',
    height: "48px",
    alignItems: "center",
    borderRadius: "12px ",
    cursor: "pointer",
  });

  const ToggleInnerbox = styled(Box)({
    color: "#1c1818",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width:"173px"
  });

  const BadgeComponents = styled(Badge)({
    "& .MuiBadge-anchorOriginTopRightRectangle": {
        top: "-14px",
        left: "20px",
        right: " 0px",
        backgroundColor: " #FF883F",
        fontSize: "81px",
        width: "10px",
        height: "10px",
        borderRadius: "20px"
    }
  });

  const AddIconNotFoundBoxs = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  });
  
  const AddIconInnerBoxs = styled(Box)({
    marginTop: "40px"
  });

  const AddImageComponents = styled("img")({
    macWidth:"100%",
    height:"auto",
  });

  const AddIconNotFoundTexts = styled(Typography)({
    marginTop: "26px",
    color: "#1C1B1B !important",
    textAlign: "center",
    fontFamily: "Poppins Arial, Helvetica, sans-serif",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "-1px"
  });

export default withStyles(styles)(UserManagement);
export {UserManagement};
// Customizable Area End
