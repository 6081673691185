// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { sortTableData } from "../../utilities/src/TableHelpers";

import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { setStorageData } from "../../../framework/src/Utilities";
import { debounce } from "lodash";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}
export interface CheckboxSelectedListTypes { value: string, id: string }

export interface TeamList{
  id: string,
  type: string,
  attributes: {
    id:number,
    name: string,
    description: string,
    is_selected: boolean,
    manager:any,
    branches: any,
    users: any,
    users_count: number
  }
}
export interface UserBranchesListing{
  id: string,
  type: string,
  attributes: {
    id: number,
    name: string,
  }
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  anchorTeamFilter:any;
  AdvanceSearchValue:string;
  filteredContentData:any;
  showContentLists:boolean;
  placeholderSelectItems:string;
  TeamList:Array<TeamList>;
  TeamId:string;
  BranchModal:boolean;
  TeamName:string,
  Branches:any,
  branchSelect:number|null,
  branchName:string,
  DeletePopup:boolean,
  DeleteId:string,
  deletealert:{ isOpen: boolean, msg?: string, type?: string },
  CreateTeam:boolean,
  showTeamToasts:{open:boolean,mess:string}
  searchList:boolean,
  anchorUserFilterContentsEl: null | HTMLElement;
creationIconsDate:string;
creationDateParamsdatas: {
  start_date: string;
  end_date: string;
};
placeholderSelectsBranch:Array<CheckboxSelectedListTypes>
anchorsEl: null | HTMLElement;
dropdownType: string;
placeholderSearchsTexts:string;
placeholderSearchBranchText:string;
UserBranchData: Array<UserBranchesListing>;
isCalendarshow:boolean;
placeholdersParams: string;
filterItemsDatas:boolean;
UserBranchDataParams:string;
expertAdminsContent:any;
placeholderSearchData:string;
placeholderSelectsData:any;
}

interface SS {
  id: any;
}

export default class TeamBuilderController extends BlockComponent<
  Props,
  S,
  SS
> {

  getTeamCollectionCallId:string="";
  getAdvanceSearchApiCallIds:string="";
  getDeleteApiCallIds:string="";
  GetBranchListApiCallIds:string="";
  expertDataApiCallIds:string="";
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage)
    ];
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      anchorTeamFilter:false,
      AdvanceSearchValue:"",
      filteredContentData:[],
      showContentLists:false,
      placeholderSelectItems:"",
      TeamList:[],
      TeamId:"",
      BranchModal:false,
      TeamName:"",
      Branches:[],
      branchSelect:null,
      branchName:"",
      DeletePopup:false,
      DeleteId:"",
      deletealert:{ isOpen: false, msg: " ", type: "success" },
      CreateTeam:false,
      showTeamToasts:{open:false,mess:""},
      searchList:false,
      anchorUserFilterContentsEl: null,
      creationIconsDate:"",
      creationDateParamsdatas: {
        start_date: "",
        end_date: ""
      },
      placeholderSelectsBranch:[],
      anchorsEl: null,
      dropdownType: "",
      placeholderSearchsTexts:"",
      placeholderSearchBranchText:"",
      UserBranchData:[],
      isCalendarshow:false,
      placeholdersParams:"",
      filterItemsDatas:false,
      UserBranchDataParams:"",
      expertAdminsContent:[],
      placeholderSearchData:"",
      placeholderSelectsData:[]
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if(message.getData(getName(MessageEnum.NavigationPayLoadMessage))?.showSnackbar){
      this.setState({
          CreateTeam:true,
      });
      this.handleAlertSuccessCloses()
    }
    if(message.getData(getName(MessageEnum.NavigationPayLoadMessage))?.showTeamToast){
      const value=message.getData(getName(MessageEnum.NavigationPayLoadMessage))?.showTeamToast
      this.setState((prev) => ({
        ...prev,
        showTeamToasts: {
          open: true,
          mess:`Changes on ${value} have been saved`
        }
      }));
      this.handleAlertSuccessClose()
    }
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)); 

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
   
    if (apiRequestCallId === this.getTeamCollectionCallId) {
      this.TeamListRes(responseJson)
      this.parseApiCatchErrorResponse(errorResponse);
    }else if(apiRequestCallId===this.getAdvanceSearchApiCallIds){
   this.setState({filteredContentData:responseJson.data})
}
else if(apiRequestCallId===this.getDeleteApiCallIds){
  this.getDeleteRes(responseJson);
}
else if (this.GetBranchListApiCallIds === apiRequestCallId) {  
  this.setState({
    UserBranchData: responseJson.data
  
  })}
  if (apiRequestCallId === this.expertDataApiCallIds) {
    this.setState({ expertAdminsContent: responseJson.data })
    this.parseApiCatchErrorResponse(errorResponse);
    }

  }
  async componentDidMount() {
    super.componentDidMount();
    this.getTeamCollection();
    this.getBranchListings();
    this.getExpertSearchListNews();
  }
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msgSuccess = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msgSuccess.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msgSuccess);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };
  sortUsers = (dataType: string, keyType: string, sortType: string) => {
    const sortedUsers = sortTableData(
      this.state.TeamList,
      dataType,
      keyType,
      sortType,
    );
    this.setState({ TeamList: sortedUsers });
  };

  handleClicksFilters = () => {
    this.setState({
      anchorUserFilterContentsEl: null
    }, () => {
      this.getTeamCollection();
    })
  }
  getExpertSearchListNews = () => {
    const token = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.expertDataApiCallIds = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_library2/experts?search=${this.state.placeholderSearchData}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  placeholderCheckboxUserManageChangeHandlers = (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const { checked, name } = event.target;
    if (checked) {
      this.setState(prevState => ({
        placeholderSelectsBranch: [...prevState.placeholderSelectsBranch, { value: name, id: itemId }]
      }), () => {
        const params = this.state.placeholderSelectsBranch.map((item: { value: string, id: string }) => `q[team_ids][]=${item.id}`).join("&");
        this.setState({
          placeholdersParams: params
        });
      });
    } else {
      this.setState(prevState => ({
        placeholderSelectsBranch: prevState.placeholderSelectsBranch.filter((item: { value: string, id: string }) => item.id !== itemId)
      }), () => {
        const params = this.state.placeholderSelectsBranch.map((item: { value: string, id: string }) => `q[team_ids][]=${item.id}`).join("&");
        this.setState({
          placeholdersParams: params
        });
      });
    }
  }
  getBranchListings=()=>{
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.GetBranchListApiCallIds = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.BranchesListingApiEndPoint}?search=${this.state.placeholderSearchBranchText}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  placeholderCheckboxUserManageChangeHandlersBranchs = (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const { checked, name } = event.target;
    if (checked) {
      this.setState(prevState => ({
        placeholderSelectsBranch: [...prevState.placeholderSelectsBranch, { value: name, id: itemId }]
      }), () => {
        const params = this.state.placeholderSelectsBranch.map((item: { value: string, id: string }) => `q[branch_ids][]=${item.id}`).join("&");
        this.setState({
          UserBranchDataParams: params
        });
      });
    } else {
      this.setState(prevState => ({
        placeholderSelectsBranch: prevState.placeholderSelectsBranch.filter((item: { value: string, id: string }) => item.id !== itemId)
      }), () => {
        const params = this.state.placeholderSelectsBranch.map((item: { value: string, id: string }) => `q[branch_ids][]=${item.id}`).join("&");
        this.setState({
          UserBranchDataParams: params
        });
      });
    }
  }

  

  handlePlaceholderUserBranchSearchChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      placeholderSearchBranchText: event.target.value
    }, () => {
      this.placeholderDebouncedBranch();
    })
  }

  placeholderDebouncedBranch: () => void = debounce(
    () => this.getBranchListings(),
    700
  )
  handlePlaceholderAssignedSearchChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      placeholderSearchData: event.target.value
    }, () => {
      this.placeholderDebouncedBranch();
    })
  }
  placeholderCheckboxAssignedExpertChangeHandles = (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const { checked, name } = event.target;
    if (checked) {
      this.setState(prevState => ({
        placeholderSelectsData: [...prevState.placeholderSelectsData, { value: name, id: itemId }]
      }), () => {
        const params = this.state.placeholderSelectsData.map((item: { value: string, id: string }) => `q[expert_ids][]=${item.id}`).join("&");
        this.setState({
          placeholdersParams: params
        });
      });
    } else {
      this.setState(prevState => ({
        placeholderSelectsData: prevState.placeholderSelectsData.filter((item: { value: string, id: string }) => item.id !== itemId)
      }), () => {
        const params = this.state.placeholderSelectsData.map((item: { value: string, id: string }) => `q[expert_ids][]=${item.id}`).join("&");
        this.setState({
          placeholdersParams: params
        });
      });
    }
  }
  dropdownHandlerOpenFun = (event: React.MouseEvent<HTMLDivElement>, dropdownType: string) => {
    this.setState({
      anchorsEl: event.currentTarget,
      dropdownType
    })
  }

  creationChangeHandlerDates = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      creationIconsDate: event.target.value
    })
  }

  dropdownHandlerCloses = () => {
    this.setState({
      anchorsEl: null,
      isCalendarshow: false,
      dropdownType: "",
    })
  }

  handleClearFilters=()=>{
    this.setState({
      anchorUserFilterContentsEl: null,
    placeholdersParams: "",
    UserBranchDataParams:"",
    placeholderSelectsBranch:[],
    creationIconsDate:"",
    creationDateParamsdatas: {
      start_date: "",
      end_date: ""
    },
    isCalendarshow:false,
    placeholderSearchsTexts:"",
    placeholderSearchBranchText:"",
    anchorsEl: null ,
    dropdownType: "",
      }, () => {
        this.getTeamCollection()
      })
  }
  ConstructEndPoints = () => {
    let params = [];
    if (this.state.AdvanceSearchValue && this.state.searchList) {
      params.push(this.searchValueParams());
    }
    if(this.state.placeholdersParams){
      params.push(this.addplaceholdersParams())
    }
    if(this.state.UserBranchDataParams){
      params.push(this.addBranchParams())
    }
    params = params.filter(param => param !== '');
    return params.length > 0 ? params.join('&') : '';
  };

  private searchValueParams(): string{
    return `search=${this.state.AdvanceSearchValue ? encodeURIComponent(this.state.AdvanceSearchValue):""}`;
  }
  private addplaceholdersParams(): string {
    return this.state.placeholdersParams ? `&${this.state.placeholdersParams}` : '';
  }
  private addBranchParams(): string {
    return this.state.UserBranchDataParams ? `&${this.state.UserBranchDataParams}` : '';
  }
  filterContainerDropdownCloseHandlers = () => {
    this.setState({
      anchorUserFilterContentsEl: null
    })
  }
  getTeamCollection=()=>{
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getTeamCollectionCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.TeamApiEndPoint}?per=&page=undefined&${this.ConstructEndPoints()}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  TeamListRes=(responseJson:any)=>{
  this.setState({TeamList:responseJson.data.reverse(),branchName:responseJson.primary_branch})
  }
  RowClick=(user:any)=>{
    this.setState({TeamId:user.id,BranchModal:true,TeamName:user.attributes?.name,
    Branches:user.attributes?.branches})
  }
  getDeleteRes=(responseJson:any)=>{
    this.setState((prev) => ({
      ...prev,
      deletealert: {
        isOpen: true,
        msg:responseJson.message,
        type:"success"
      },
      DeletePopup:false
    }));
  this.handleAlertSuccessClose()
  this.getTeamCollection();
  }
  handleAlertSuccessCloses=()=>{
    setTimeout(() => {
      this.setState({CreateTeam:false});
    }, 2000);
  }
  handleCancel=()=>{
  this.setState({BranchModal:false})
  }
  handleTeamPopup=(id:any)=>{
    this.setState({BranchModal:false , DeletePopup:true,DeleteId:id}) 
  }
  handleEditOpenPop=()=>{
    this.props.navigation.navigate("CreateTeam", {
      type: "Edit",id:this.state.TeamId
    });
    setStorageData("editTeam",this.state.TeamId)
  }
  handleDelete=(id:any)=>{
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getDeleteApiCallIds = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.TeamApiEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.delete
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  
  }
  handleAlertSuccessClose=()=>{
    setTimeout(() => {
      this.setState((prev) => ({
        ...prev,
        deletealert: {
          isOpen: false,
        },
        showTeamToasts:{
          open:false,
          mess:""
        }
      }));
    }, 3000);
  }
  cancelContentDeleteDialog=()=>{
    this.setState({DeletePopup:false})
  }
  getAdvancedSearchLists=()=>{
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getAdvanceSearchApiCallIds = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.AdvancedSearchEndPoint}?type=team&search=${this.state.AdvanceSearchValue}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  
  }
  handleCreateTeam=()=>{
    this.props.navigation.navigate("CreateTeam", {
      type: "Create"
    });
  }
  handleEditIcon=async(ids:any)=>{
    this.props.navigation.navigate("CreateTeam", {
      type: "Edit",id:ids
    });
    setStorageData("editTeam",ids)
  }
  handleTeamInputChangeSearch = (event:any) => {
    this.setState({AdvanceSearchValue:event.target.value},() => {
      this.getTeamCollection()
    })
    this.setState({searchList:false,showContentLists:event.target.value !== ""},()=>{
      if (this.state.AdvanceSearchValue !== "") {
        this.getAdvancedSearchLists()
      }
    }) 
  }

     handleThumbnailListClick=(item:string)=>{
      this.setState({
        searchList:true,
        AdvanceSearchValue: item,
        showContentLists: false
      },()=>{
         this.getTeamCollection()
      })
     }
     handleFilterDropClick = (event: React.MouseEvent<HTMLDivElement>) => {
      this.setState({
         filterItemsDatas: !this.state. filterItemsDatas,
        anchorUserFilterContentsEl: event.currentTarget,
      })
    };
  
  HandleselectBranch=(index:number)=>{
    const id=this.state.Branches?.filter((i:any,inx:number)=>(inx===index))
      this.setState({branchSelect:id[0]})
    
  }
}

// Customizable Area End
