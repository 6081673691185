import React from 'react';
// Customizable Area Start
import { Button, FormControlLabel, Popover, RadioGroup, Typography, styled } from '@material-ui/core';
import MaterialUIBox from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import Calendar from 'react-calendar';
import FilterItemsControllerWeb, { Props, ExpertList } from "./FilterItemsControllerWeb";
import { AdminCatagoryListing, AdminContentRoles, CheckboxSelectedListType} from '../../catalogue/src/CatalogueController';

// Customizable Area End

export default class FilterItems extends FilterItemsControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDropdown() {
    const assignedValue=this.props.isThumbnail?"Creator Name":"Assigned expert"
    switch (this.props.dropdownType) {
      case assignedValue:
        return this.renderPlaceholderDropdownContent();
      case "Manager":
          return this.renderPlaceholderDropdownContent();
      case "creation date":
        return this.renderCreationDateDropdownContent();
      case "status":
        return this.renderStatusDropdownContent();
      case "content role":
        return this.renderContentRoleDropdownContent();
      case "category":
        return this.renderCategoryDropdownContent();
      default:
        break;
    }
  }

  renderContentDropdown(){
    switch (this.props.dropdownType) {
      case "tags":
        return this.renderTagsDropdownContent();
      case "creation date":
        return this.renderCreationDateDropdownContent();
      case "creation name":
        return this.renderCreationName();
      case "file type":
      return this.renderFileType();
      default:
        break;
    }
  }

  renderUserManagementDropdown(){
    switch (this.props.dropdownType) {
      case "teams":
        return this.renderUserManagementTeamsDropdownContent();
      case "branches":
        return this.renderUserManagementBranchDropdownContent();
      default:
        break;
    }
  }

  renderContentCreationDateDropdownContent() {
    const isSelected = this.props.contentCreationDate;
    return <DropdownContent>
      {
        this.props.isCalendarOpen ? 
        <>
        <Calendar 
          selectRange={true}
          onChange={this.props.handleCalendarChange}
        /> 
        </>
        :
        <>
      <RadioGroup className="checkboxList" name="duration" value={isSelected && isSelected[0].value} onChange={this.props.creationDateChangeHandler}>
        {["Last Week", "Last Month", "Last Year"].map((duration: string) => {
          return (
            <FormControlLabel key={duration} value={duration} control={<StyledRadio />} label={duration} className={isSelected && isSelected[0].value === duration ? "selectedStyle" : "checkboxContent"} />
          )
        })}
      </RadioGroup>
      <Button variant="text" className="applyBtn" onClick={this.props.handleCalendarOpen}>Select Range</Button>
      </>
      }
    </DropdownContent>
  }

  renderPopover() {
    const open = Boolean(this.props.anchorEl);
    const id=this.props.navigation && this.props.navigation.getParam("id")
    const type=this.props.navigation && this.props.navigation.getParam("type")  
    return <Popover
      className="myPopover"
      open={open}
      anchorEl={this.props.anchorEl}
      onClose={this.props.dropdownCloseHandler}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      data-test-id= {"dropDownTestID"}
    >
      {
      (window.location.pathname === "/ContentManagement"||window.location.pathname.startsWith("/ProgramContent")|| window.location.pathname === "/Iconmanagement" ||window.location.pathname === "/BulkUploading" || window.location.pathname === "/TeamBuilder" || window.location.pathname === "/Customform" || window.location.pathname==="/Thumbnailmanagement"||window.location.pathname === `/CourseCreation/${id}/${type}` || window.location.pathname=== "/ContentRoleCreationWeb" || window.location.pathname === `/ProgramCreation/${id}/${type}`)  &&
    this.renderDropdown()
     }
      {window.location.pathname === "/ManageContentLibrary" &&
    this.renderContentDropdown()
          }
      {(window.location.pathname === "/TeamBuilder" || window.location.pathname === "/usermanagement") &&
    this.renderUserManagementDropdown()
          }
      
    </Popover>
  }

  renderPlaceholderDropdownContent() {
    return <DropdownContent>
      <TextFieldSearch
        variant="outlined"
        placeholder="Search"
        value={this.props.placeholderSearchText}
        onChange={this.props.handlePlaceholderSearchChange}
        InputProps={{
          startAdornment: (
            <SearchIcon />
          ),
        }}
      />
      <MaterialUIBox className="checkboxList">
        {this.props.expertAdminList && this.props.expertAdminList.filter((option: ExpertList) => option.attributes.full_name.toLowerCase().includes((this.props.placeholderSearchText ?? "").toLowerCase())).map((option) => {
          const isChecked = this.props.placeholderSelectedItems.find((item: {value:string, id:string}) => item.value === option.attributes.full_name);
          return (
            <FormControlLabel key={option.id} className={isChecked ? "checkboxContent selectedStyle" : "checkboxContent"} control={<CheckboxMain  data-test-id="filterTestId" checked={isChecked ? true : false} name={option.attributes.full_name} test-id={`option_id${option.id}`} onChange={(event:React.ChangeEvent<HTMLInputElement>)=>this.props.placeholderCheckboxChangeHandler && this.props.placeholderCheckboxChangeHandler(event, option.id)}/>} label={option.attributes.full_name} />
          )
        })}
      </MaterialUIBox>
      <Button variant="text" className="applyBtn" onClick={this.props.dropdownCloseHandler}>Apply</Button>
    </DropdownContent>
  }

  // ManageContentLibrary

  renderTagsDropdownContent() {
    return <DropdownContent>
      <TextFieldSearch
        variant="outlined"
        placeholder="Search"
        value={this.props.placeholderSearchText}
        onChange={this.props.handleTagsSearchChange}
        InputProps={{
          startAdornment: (
            <SearchIcon />
          ),
        }}
      />
      <MaterialUIBox data-test-id = "checkData1" className="checkboxList">
        {this.props.tagListing && this.props.tagListing.filter((tag: any) => tag.attributes.name.toLowerCase().includes((this.props.placeholderSearchText ?? "").toLowerCase())).map((tag) => {
          const isChecked = this.props.tagsSelectedItems && this.props.tagsSelectedItems.find((item: {value:string, id:string}) => item.value === tag.attributes.name);
          return (
            <FormControlLabel key={tag.id} className={isChecked ? "checkboxContent selectedStyle" : "checkboxContent"} control={<CheckboxMain  data-test-id="filterTestId" checked={isChecked ? true : false} name={tag.attributes.name} onChange={(event:React.ChangeEvent<HTMLInputElement>)=> {this.props.tagsCheckboxChangeHandler && this.props.tagsCheckboxChangeHandler(event, tag.id)}}/>} label={tag.attributes.name} />
          )
        })}
      </MaterialUIBox>
      <Button variant="text" className="applyBtn" onClick={this.props.dropdownCloseHandler}>Apply</Button>
    </DropdownContent>
  }

  renderUserManagementTeamsDropdownContent() {
    return <DropdownContent>
      <TextFieldSearch
        variant="outlined"
        placeholder="Search"
        value={this.props.placeholderSearchText}
        onChange={this.props.handlePlaceholderUserSearchChange}
        InputProps={{
          startAdornment: (
            <SearchIcon />
          ),
        }}
      />
      <MaterialUIBox data-test-id = "checkData1" className="checkboxList">
        {this.props.userTeamsList && this.props.userTeamsList.filter((teams: any) => teams.attributes.name.toLowerCase().includes((this.props.placeholderSearchText ?? "").toLowerCase())).map((teams) => {
          const isChecked = this.props.placeholderSelectedTeamsItems && this.props.placeholderSelectedTeamsItems.find((item: {value:string, id:string}) => item.value === teams.attributes.name);
          return (
            <FormControlLabel key={teams.id} className={isChecked ? "checkboxContent selectedStyle" : "checkboxContent"} control={<CheckboxMain  data-test-id="filterTestId" checked={isChecked ? true : false} name={teams.attributes.name} onChange={(event:React.ChangeEvent<HTMLInputElement>)=> {this.props.placeholderCheckboxUserManageChangeHandler && this.props.placeholderCheckboxUserManageChangeHandler(event, teams.id)}}/>} label={teams.attributes.name} />
          )
        })}
        
      </MaterialUIBox>
      <Button variant="text" className="applyBtn" onClick={this.props.dropdownCloseHandler}>Apply</Button>
    </DropdownContent>
  }

  renderUserManagementBranchDropdownContent() {
   
   return <DropdownContent>
      <TextFieldSearch
        variant="outlined"
        placeholder="Search"
        value={this.props.placeholderSearchBranchText}
        onChange={this.props.handlePlaceholderUserSearchChangeBranch}
        InputProps={{
          startAdornment: (
            <SearchIcon />
          ),
        }}
      />
      <MaterialUIBox data-test-id = "checkData1" className="checkboxList">
        {this.props.userBranchList && this.props.userBranchList.filter((branch: any) => branch.attributes.name.toLowerCase().includes((this.props.placeholderSearchText ?? "").toLowerCase())).map((branches) => {
          const isChecked = this.props.placeholderSelectedBranchItems && this.props.placeholderSelectedBranchItems.find((item: {value:string, id:string}) => item.value === branches.attributes.name);
          return (
            <FormControlLabel key={branches.id} className={isChecked ? "checkboxContent selectedStyle" : "checkboxContent"} control={<CheckboxMain  data-test-id="filterTestId" checked={isChecked ? true : false} name={branches.attributes.name} onChange={(event:React.ChangeEvent<HTMLInputElement>)=> {this.props.placeholderCheckboxUserManageChangeHandlerBranch && this.props.placeholderCheckboxUserManageChangeHandlerBranch(event, branches.id)}}/>} label={branches.attributes.name} />
          )
        })}
      </MaterialUIBox>
      <Button variant="text" className="applyBtn" onClick={this.props.dropdownCloseHandler}>Apply</Button>
    </DropdownContent>
  }

  renderCreationName(){
    return <DropdownContent>
        <TextFieldSearch
        variant="outlined"
        placeholder="Search"
        value={this.props.placeholderCreatorSearchText}
        onChange={this.props.handleCreatorSearchChange}
        InputProps={{
          startAdornment: (
            <SearchIcon />
          ),
        }}
      />
      <MaterialUIBox data-test-id = "checkData" className="checkboxList">
        {this.props.createrNameListing && this.props.createrNameListing.filter((creation: any) => creation.attributes.full_name.toLowerCase().includes(this.props.placeholderCreatorSearchText && this.props.placeholderCreatorSearchText.toLowerCase())).map((creation) => {
          const isChecked = this.props.creationSelectedItems && this.props.creationSelectedItems.find((item: CheckboxSelectedListType) => item.value === creation.attributes.full_name)
          return (
            <FormControlLabel 
            
            key={creation.id} 
            className={isChecked ? "checkboxContent selectedStyle" : "checkboxContent"} 
            control={<CheckboxMain checked={isChecked ? true : false} 
            name={creation.attributes.full_name} 
            onChange={(event:React.ChangeEvent<HTMLInputElement>)=> this.props.creationCheckboxChangeHandler && this.props.creationCheckboxChangeHandler(event, creation.id)}/>} 
            label={creation.attributes.full_name} 
            />
          )
        })}
      </MaterialUIBox>
      <Button variant="text" className="applyBtn" onClick={this.props.dropdownCloseHandler}>Apply</Button>
    </DropdownContent>
  }

  renderFileType(){
    return <DropdownContent>
      <MaterialUIBox data-test-id = "checkData2" className="checkboxList">
        {["audio", "video", "document"].map((doc) => {
          const isChecked = this.props.fileSelectedItems && this.props.fileSelectedItems.find((item: {[key:string]:string}) => item.value === doc)
          return (
            <FormControlLabel 
            key={doc} 
            className={isChecked ? "checkboxContent selectedStyle" : "checkboxContent"} 
            control={<CheckboxMain checked={isChecked ? true : false} 
            name={doc} 
            onChange={(event:React.ChangeEvent<HTMLInputElement>)=>this.props.fileCheckboxChangeHandler && this.props.fileCheckboxChangeHandler(event, doc)}/>} 
            label={doc} 
            />
          )
        })}
      </MaterialUIBox>
      <Button variant="text" className="applyBtn" onClick={this.props.dropdownCloseHandler}>Apply</Button>
    </DropdownContent>
  }

  renderCreationDateDropdownContent() {
    const isSelected = this.props.creationDate;
    return <DropdownContent>
      {
        this.props.isCalendarOpen ? 
        <>
        <Calendar 
          selectRange={true}
          onChange={this.props.handleCalendarChange}
        /> 
        </>
        :
        <>
      <RadioGroup className="checkboxList" name="duration" value={isSelected} onChange={this.props.creationDateChangeHandler}>
        {["Last Week", "Last Month", "Last Year"].map((duration: string) => {
          return (
            <FormControlLabel key={duration} value={duration} control={<StyledRadio />} label={duration} className={isSelected === duration ? "selectedStyle" : "checkboxContent"} />
          )
        })}
      </RadioGroup>
      <Button variant="text" className="applyBtn" onClick={this.props.handleCalendarOpen}>Select Range</Button>
      </>
      }
    </DropdownContent>
  }

  renderStatusDropdownContent() {
    return <StatusDropdownContent>
      <MaterialUIBox className="status status1" onClick={() =>this.props.handleChangeStatus && this.props.handleChangeStatus("Archived")}>Archived</MaterialUIBox>
      <MaterialUIBox className="status status2" onClick={() => this.props.handleChangeStatus && this.props.handleChangeStatus("Active")}>Active</MaterialUIBox>
      <MaterialUIBox className="status status3" onClick={() =>this.props.handleChangeStatus && this.props.handleChangeStatus("Draft")}>Draft</MaterialUIBox>
    </StatusDropdownContent>
  }
  renderContentRoleDropdownContent() {
    const {contentRoleSearchText = ""} = this.props;
    return <DropdownContent>
      <TextFieldSearch
        variant="outlined"
        placeholder="Search"
        value={this.props.contentRoleSearchText}
        onChange={this.props.handleContentRoleSearchChange}
        InputProps={{
          startAdornment: (
            <SearchIcon />
          ),
        }}
      />
      <MaterialUIBox className="checkboxList">
        {this.props.adminContentRolesList && this.props.adminContentRolesList.filter((option:AdminContentRoles) => option.attributes.name.toLowerCase().includes(contentRoleSearchText.toLowerCase())).map((option) => {
          const isChecked = this.props.contentRoleSelectedItems && this.props.contentRoleSelectedItems.find((item: CheckboxSelectedListType) => item.value === option.attributes.name)
          return (
            <FormControlLabel key={option.id} className={isChecked ? "checkboxContent selectedStyle" : "checkboxContent"} control={<CheckboxMain checked={isChecked ? true : false} name={option.attributes.name} onChange={(event:React.ChangeEvent<HTMLInputElement>)=>this.props.contentRoleCheckboxChangeHandler && this.props.contentRoleCheckboxChangeHandler(event, option.id)}/>} label={option.attributes.name} />
          )
        })}
      </MaterialUIBox>
      <Button variant="text" className="applyBtn" onClick={this.props.dropdownCloseHandler}>Apply</Button>
    </DropdownContent>
  }

  renderCategoryDropdownContent() {
    const {  categorySearchText = ""} = this.props
    return <DropdownContent>
      <TextFieldSearch
        variant="outlined"
        placeholder="Search"
        value={this.props.categorySearchText}
        onChange={this.props.handleCategorySearchChange}
        InputProps={{
          startAdornment: (
            <SearchIcon />
          ),
        }}
      />
      <MaterialUIBox data-test-id = "checkData3"  className="checkboxList">
        {this.props.adminCatagoryList && this.props.adminCatagoryList.filter((option: AdminCatagoryListing) => option.attributes.name.toLowerCase().includes(categorySearchText.toLowerCase())).map((option) => {
          const isChecked = this.props.categorySelectedItems && this.props.categorySelectedItems.find((item: CheckboxSelectedListType) => item.value === option.attributes.name)
          return (
            <FormControlLabel key={option.id} className={isChecked ? "checkboxContent selectedStyle" : "checkboxContent"} control={<CheckboxMain checked={isChecked ? true : false} name={option.attributes.name} onChange={(event:React.ChangeEvent<HTMLInputElement>)=> this.props.categoryCheckboxChangeHandler && this.props.categoryCheckboxChangeHandler(event, option.id)}/>} label={option.attributes.name} />
          )
        })}
      </MaterialUIBox>
      <Button variant="text" className="applyBtn" onClick={this.props.dropdownCloseHandler}>Apply</Button>
    </DropdownContent>
  }

   renderCheckboxSelectedItems(title:string,list:Array<{value:string, id:string}>=[] ) {
    switch(list?.length) {
        case 0:
            return <Typography className="title">{title}</Typography>;
        case 1:
            return <Typography className="title">{list[0]?.value}</Typography>;
        default:
            const firstNames = list[0].value;
            const remainingCount = list.length - 1; 
            return (
                <Typography className="title">
                    {firstNames} & {remainingCount} more
                </Typography>
            );
    }
}

applySelectStyle(dropdownType:string, selectedItem: string | CheckboxSelectedListType[] | undefined, isBorder: boolean){
  if(isBorder){
    return (this.props.anchorEl && this.props.dropdownType === dropdownType) ||selectedItem && selectedItem.length ? "selectCommonStyle selectActive" : "selectCommonStyle"
  } else{
    return (this.props.anchorEl && this.props.dropdownType === dropdownType) ||selectedItem && selectedItem.length ? "arrowIconStyle" : ""
  }
}
renderMainFirstBox=(assignedValue:any)=>{
  return(
<MainFirstBox>
      {!this.props.isContentRole && 
      <StyledDropdownClickBox className={this.applySelectStyle(assignedValue, this.props.placeholderSelectedItems, true)}
       onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => this.props.dropdownOpenHandler(event, assignedValue)}
       >
        <MaterialUIBox className="contentContainer">
        {
          this.renderCheckboxSelectedItems(assignedValue,this.props.placeholderSelectedItems)
         
        }
        </MaterialUIBox>
        {this.props.dropdownType === assignedValue ? <ExpandLess className={this.applySelectStyle(assignedValue, this.props.placeholderSelectedItems, false)} /> : <ExpandMore className={this.applySelectStyle(assignedValue, this.props.placeholderSelectedItems, false)} />}
      </StyledDropdownClickBox>}
      { this.props.isContentRole &&
      <StyledDropdownClickBox className={this.applySelectStyle("status", this.props.selectedStatus, true)}
       onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => this.props.dropdownOpenHandler(event, "status")}
       >
      <MaterialUIBox className="contentContainer">
        <Typography className="title">
        {
          this.props.selectedStatus || "Status"
        }
        </Typography>
        </MaterialUIBox>
        {this.props.dropdownType !== "status" ?  <ExpandMore  className={this.applySelectStyle("status", this.props.selectedStatus, false)}/>:<ExpandLess  className={this.applySelectStyle("status", this.props.selectedStatus, false)}/> }
      </StyledDropdownClickBox>
      }
      <StyledDropdownClickBox className={this.applySelectStyle("creation date", this.props.creationDate, true)}
       onClick={(event: React.MouseEvent<HTMLDivElement , MouseEvent>) => this.props.dropdownOpenHandler(event, "creation date")}
       >
      <MaterialUIBox className="contentContainer">
          <Typography className="title">{this.props.creationDate ? this.props.creationDate : "Creation Date"}</Typography>
        </MaterialUIBox>
        {this.props.dropdownType === "creation date" ? <ExpandLess className={this.applySelectStyle("creation date", this.props.creationDate, false)} /> : <ExpandMore className={this.applySelectStyle("creation date", this.props.creationDate, false)} />}
      </StyledDropdownClickBox>
    </MainFirstBox>
  )
}

renderContentManagement = () => { 
  const id=this.props.navigation && this.props.navigation.getParam("id")
  const type=this.props.navigation && this.props.navigation.getParam("type")
  const assignedValue=this.props.isThumbnail?"Creator Name":"Assigned expert"
  return(
    (window.location.pathname === "/ContentManagement"|| window.location.pathname.startsWith("/ProgramContent") || window.location.pathname === "/BulkUploading"||  window.location.pathname === "/Customform" || window.location.pathname==="/Thumbnailmanagement" ||window.location.pathname === "/Iconmanagement" || window.location.pathname === "/ContentRoleCreationWeb" || window.location.pathname === `/CourseCreation/${id}/${type}` || window.location.pathname === `/ProgramCreation/${id}/${type}`)  &&
    <MaterialUIBox
    p={3}
    style={{
      padding: '26px 14px 10px',
      borderRadius: "14px",
      background: "#FFF",
      boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
      borderTopRightRadius: "0px",
      borderTopLeftRadius: "0px",
      width: "511px"
    }}
  >
    {this.renderMainFirstBox(assignedValue)}
  {!this.props.isThumbnail && 
    <MainSecondBox marginTop={2}>
      {!this.props.isContentRole &&(<>
      <StyledDropdownClickBox className={this.applySelectStyle("status", this.props.selectedStatus, true)}
       onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => this.props.dropdownOpenHandler(event, "status")}
       >
      <MaterialUIBox className="contentContainer">
        <Typography className="title">
        {
          this.props.selectedStatus ||  "Status"
        }
        </Typography>
        </MaterialUIBox>
        {this.props.dropdownType === "status" ? <ExpandLess  className={this.applySelectStyle("status", this.props.selectedStatus, false)}/> : <ExpandMore  className={this.applySelectStyle("status", this.props.selectedStatus, false)}/>}
      </StyledDropdownClickBox>
      
      <StyledDropdownClickBox 
       className={this.applySelectStyle("content role", this.props.contentRoleSelectedItems, true)}
       onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => this.props.dropdownOpenHandler(event, "content role")}
       >
      <MaterialUIBox className="contentContainer contentRole">
        <MaterialUIBox className="innerContentBox">
           {
            this.renderCheckboxSelectedItems("Content Role",this.props.contentRoleSelectedItems)
          }
          </MaterialUIBox>
        </MaterialUIBox>
        {this.props.dropdownType === "content role" ? <ExpandLess className={this.applySelectStyle("content role", this.props.contentRoleSelectedItems, false)}/> : <ExpandMore className={this.applySelectStyle("content role", this.props.contentRoleSelectedItems, false)} />}
      </StyledDropdownClickBox>
      <StyledDropdownClickBox 
       className={this.applySelectStyle("category", this.props.categorySelectedItems, true)}
      onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => this.props.dropdownOpenHandler(event, "category")}
      >
      <MaterialUIBox className="contentContainer contentRole">
      <MaterialUIBox className="innerContentBox">
        {
            this.renderCheckboxSelectedItems("Category",this.props.categorySelectedItems)
        }
        </MaterialUIBox>
        </MaterialUIBox>
        {this.props.dropdownType === "category" ? <ExpandLess className={this.applySelectStyle("category", this.props.categorySelectedItems, false)} /> : <ExpandMore className={this.applySelectStyle("category", this.props.categorySelectedItems, false)} />}
      </StyledDropdownClickBox></>)}
    </MainSecondBox>}
    <StyledButtonContainer>
      <ButtonFirst onClick={this.props.handleClear} variant="contained" style={{ marginRight: 10, textTransform: "capitalize", fontFamily:"Poppins, sans-serif" }}>
        Clear filters
      </ButtonFirst>
      <ButtonSecond data-test-id= {"filterAPiTestId"} onClick={this.props.handleClick} variant="contained" style={{ textTransform: "capitalize" }}>
        Filter
      </ButtonSecond>
    </StyledButtonContainer>
  </MaterialUIBox>
  )
 
}

renderDropdownClickBox = (label: string, items: CheckboxSelectedListType[] | undefined , type: string) => (
  <StyledDropdownClickBox
    className={this.applySelectStyle(label, items, true)}
    onClick={(event) => this.props.dropdownOpenHandler(event, type)}
  >
    <MaterialUIBox className="contentContainer">
      {items && this.renderCheckboxSelectedItems(label,items)}
    </MaterialUIBox>
    {this.props.dropdownType === type ? (
      <ExpandLess className={items && this.applySelectStyle(label, items, false)} />
    ) : (
      <ExpandMore className={items && this.applySelectStyle(label, items, false)} />
    )}
  </StyledDropdownClickBox>
);

renderUserManagementDropdownClickBox = (label: string, items: CheckboxSelectedListType[] | undefined , type: string) => (
  <StyledDropdownClickBox
  onClick={(event) => this.props.dropdownOpenHandler(event, type)}
  className={this.applySelectStyle(label, items, true)}
  >
    <MaterialUIBox className="contentContainer" >
      {items && this.renderCheckboxSelectedItems(label,items )}
    </MaterialUIBox>
    {this.props.dropdownType === type ? (
      <ExpandLess className={items && this.applySelectStyle(label, items, false)}/>
    ) : (
      <ExpandMore className={items && this.applySelectStyle(label, items, false)}/>
    )}
  </StyledDropdownClickBox>
);

renderManageContent = () => {
  const { pathname } = window.location;
  const {
    tagsSelectedItems,
    contentCreationDate,
    creationSelectedItems,
    fileSelectedItems,
  } = this.props;

  if (pathname !== "/ManageContentLibrary") return null;
  return (
    <MaterialUIBox
      p={3}
      style={{
        padding: '26px 14px 10px',
        borderRadius: "14px",
        background: "#FFF",
        boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
        borderTopRightRadius: "0px",
        borderTopLeftRadius: "0px",
        width: "511px",
        boxSizing: "border-box"
      }}
    >
      <MainFirstBox>
        {this.renderDropdownClickBox("Tags", tagsSelectedItems, "tags")}
        {this.renderDropdownClickBox("Creation Date", contentCreationDate, "creation date")}
      </MainFirstBox>
      <MainSecondBox marginTop={2}>
        {this.renderDropdownClickBox("Creator Name", creationSelectedItems, "creation name")}
        {this.renderDropdownClickBox("File type", fileSelectedItems, "file type")}
      </MainSecondBox>
      <StyledButtonContainer>
        <ButtonFirst onClick={this.props.handleClear} variant="contained" style={{ marginRight: 10, textTransform: "capitalize", fontFamily: "Poppins, sans-serif" }}>
          Clear filters
        </ButtonFirst>
        <ButtonSecond data-test-id="filterAPiTestId" onClick={this.props.handleClick} variant="contained" style={{ textTransform: "capitalize" }}>
          Filter
        </ButtonSecond>
      </StyledButtonContainer>
    </MaterialUIBox>
  );
};

renderUserManagementContent = () => {
  const { pathname } = window.location;
  const {
    placeholderSelectedTeamsItems,
    placeholderSelectedBranchItems
  } = this.props;

  return (
    <>{(pathname === "/TeamBuilder" || pathname === "/usermanagement")  && (    <MaterialUIBox
      p={3}
      style={{
        padding: '26px 14px 10px',
        borderRadius: "14px",
        background: "#FFF",
        boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
        borderTopRightRadius: "0px",
        borderTopLeftRadius: "0px",
        width: "511px",
        boxSizing: "border-box"
      }}
    >
      <MainFirstBox>
      {!this.props.isTeamBuilder && 
        this.renderUserManagementDropdownClickBox("Teams", placeholderSelectedTeamsItems, "teams")
          }
        {this.renderUserManagementDropdownClickBox("Branches", placeholderSelectedBranchItems, "branches")}
        <StyledDropdownClickBox className={this.applySelectStyle("Manager", this.props.placeholderSelectedItems, true)}
       onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => this.props.dropdownOpenHandler(event, "Manager")}
       >
        <MaterialUIBox className="contentContainer">
        {
          this.renderCheckboxSelectedItems("Manager",this.props.placeholderSelectedItems)
         
        }
        </MaterialUIBox>
        {this.props.dropdownType === "Manager" ? <ExpandLess className={this.applySelectStyle("Manager", this.props.placeholderSelectedItems, false)} /> : <ExpandMore className={this.applySelectStyle("Manager", this.props.placeholderSelectedItems, false)} />}
      </StyledDropdownClickBox>
      </MainFirstBox>
      <StyledButtonContainer>
        <ButtonFirst onClick={this.props.handleClear} variant="contained" style={{ marginRight: 10, textTransform: "capitalize", fontFamily: "Poppins, sans-serif" }}>
          Clear filters
        </ButtonFirst>
        <ButtonSecond data-test-id="filterAPiTestId" onClick={this.props.handleClick} variant="contained" style={{ textTransform: "capitalize" }}>
          Filter
        </ButtonSecond>
      </StyledButtonContainer>
    </MaterialUIBox>) }</>

  );
};
// Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        {this.renderPopover()}
        {this.renderContentManagement()}
        {this.renderManageContent()}
        {this.renderUserManagementContent()}
      </>
    );
    // Customizable Area End

  }
}

// Customizable Area Start

const StyledButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '20px',
  padding: '32px 0px 16px 24px',
  [theme.breakpoints.down('md')]: {
    padding: '16px 0px 8px 16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  [theme.breakpoints.down('sm')]: {
    gap: '10px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

const MainFirstBox = styled(MaterialUIBox)(({ theme }) => ({
  '& .MuiSelect-selectMenu': {
    paddingLeft: '10px',
  },
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: '12px',
  fontFamily: 'Poppins, sans-serif',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

const MainSecondBox = styled(MaterialUIBox)(({ theme }) => ({
  '& .MuiSelect-selectMenu': {
    paddingLeft: '10px',
  },
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  flexWrap: 'wrap',
  gap: '12px',
  fontFamily: 'Poppins, sans-serif',
  '& .contentRole:focus': {
    backgroundColor: '#EEE8F2',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  [theme.breakpoints.down('sm')]: {
    gap: '8px',
  },
}));


const CheckboxMain = styled(Checkbox)({
  color: '#652786 !important',
});


const ButtonFirst = styled(Button)(({ theme }) => ({
  display: 'flex !important',
  width: '183px !important',
  maxWidth: '183px !important', 
  height: '40px !important',
  padding: '16px 32px !important',
  justifyContent: 'center !important',
  alignItems: 'center !important',
  gap: '10px !important',
  borderRadius: '12px !important',
  border: '1px solid #e47004 !important',
  backgroundColor: '#fff !important',
  color: '#e47004 !important',
  fontSize: '16px !important',
  fontStyle: 'normal !important',
  fontWeight: 600,
  lineHeight: '16px !important',
  [theme.breakpoints.down('sm')]: {
    width: '100% !important',
    padding: '8px 16px !important',
    marginBottom: '10px !important',
  },
}));

const ButtonSecond = styled(Button)(({ theme }) => ({
  display: 'flex !important',
  width: '183px !important',
  maxWidth: '183px !important',
  height: '40px !important',
  padding: '16px 32px !important',
  justifyContent: 'center !important',
  alignItems: 'center !important',
  gap: '10px !important',
  borderRadius: '12px !important',
  backgroundColor: '#652786 !important',
  color: '#fff !important',
  fontSize: '16px !important',
  fontStyle: 'normal !important',
  fontWeight: 600,
  lineHeight: '16px !important',
  [theme.breakpoints.down('sm')]: {
    width: '100% !important',
    padding: '8px 16px !important',
  },
}));

const TextFieldSearch = styled(TextField)({
  "& .MuiOutlinedInput-notchedOutline":{
    backgroundColor: "#fcf6f6"
  },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "lightgray"
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "lightgray"
  },
  "& .MuiSvgIcon-root":{
    position: "relative",
    zIndex: 1,
    color: "gray"
  },
  "& .MuiOutlinedInput-root":{
    borderRadius: "12px",
    height: "48px"
  },
  "& .MuiOutlinedInput-input":{
    position: "relative",
    zIndex: 1,
  },
  "& .MuiOutlinedInput-inputAdornedStart":{
    paddingLeft: "6px"
  }
});

const StyledDropdownClickBox = styled('div')({
  display: "flex",
  alignItems: "center",
  gap: "12px",
  padding: "8px 16px !important",
  border: "1px solid #ADAAAA",
  borderRadius: "42px",
  backgroundColor: "#fcf6f6",
  cursor: "pointer",
  "&.selectCommonStyle .contentContainer":{
    display: "flex",
    gap: "3px",
    width: "100%"
  },
  "&.selectActive":{
    backgroundColor: "#EEE8F2",
    borderColor: "#652786"
  },
  "&.selectActive .title":{
    color: "#652786"
  },
 "&.creationDate":{
  display: "flex",
  justifyContent: "space-between"
 },
 "& .contentRole ":{
  width: "100% !important",
 display: "flex !important",
 justifyContent: "space-between !important"
 },
  "& .innerContentBox":{
    display: "flex",
    gap: "3px"
  },
  "& .title":{
    whiteSpace: "nowrap",
    fontSize: "16px",
    fontWeight: 400,
    color: "#484646",
    fontFamily: "Poppins, sans-serif"
  },
  "& .arrowIconStyle":{
    color: "#652786"
  }
});

const DropdownContent = styled(MaterialUIBox)({
  padding: "8px 16px",
  width: "254px",
  maxHeight:"500px",
  "& .checkboxList": {
    marginTop: "5px",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginBottom: "16px"
  },
  "& .checkboxContent": {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid lightgray",
    fontFamily: "Poppins , sans-serif",
    textTransform: "capitalize"
  },  "& .MuiTypography-body1":{
    fontFamily:"Poppins, sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    color: "#1C1B1B"
  },
  "& .selectedStyle": {
    backgroundColor: "#EEE8F2",
    borderBottom: "1px solid transparent !important",
    borderRadius: "8px",
  },
  "& .selectedStyle .MuiTypography-body1":{
    color: "#542070 !important"
  },
  "& .checkboxContent:hover": {
    backgroundColor: "#EEE8F2",
    borderBottom: "1px solid transparent",
    borderRadius: "8px",
  },
  "& .applyBtn": {
    display: "block",
    margin: "auto",
    color: "#542070",
    textTransform: "capitalize",
    fontSize: "16px"
  },
  "& .MuiFormControlLabel-root": {
    margin: 0,
    borderBottom: "1px solid lightgray",
    fontFamily:"Poppins, sans-serif"
  },
  "& .react-calendar":{
    border: "unset",
    fontWeight: 500,
    fontSize: "14px"
  },
  "& .react-calendar abbr":{
    textDecoration: "none"
  },
  "& .react-calendar__month-view__days__day":{
    width: "31px",
    height: "31px",
    borderRadius: "50%",
    fontWeight: 400,
    fontSize: "14px !important"
  },
  "& .react-calendar__month-view__weekdays":{
    fontWeight: 400,
    fontSize: "10px !important"
  },
  "& .react-calendar__tile--now:enabled":{
    backgroundColor: "#652786",
    color: "#fff"
  },
  "& .react-calendar__tile--now:enabled:hover":{
    backgroundColor: "#652786"
  },
  "& .react-calendar__month-view__days__day--weekend": {
    color: "rgba(0, 0, 0, 0.87)"
  },
  "& .react-calendar__month-view__days__day--neighboringMonth":{
    color: "#757575"
  },
  "& .react-calendar__tile--active":{
    color: "#fff",
    backgroundColor: "#652786 !important"
  },
  "& .react-calendar__year-view__months__month":{
    width: "74px",
    height: "74px",
    borderRadius:"50%"
  },
  "& .react-calendar__navigation__prev2-button":{
    display: "none"
  },
  "& .react-calendar__navigation__next2-button":{
    display: "none"
  },
  "& .react-calendar__navigation button:enabled:hover":{
    backgroundColor: "unset"
  },
  "& .react-calendar__navigation button":{
    minWidth: "auto"
  },
  "& .calendarBtnContainer":{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "12px",
    marginTop: "16px"
  }
});

const StyledRadio = styled(Radio)({
  color: "#652786 !important"
});

const StatusDropdownContent = styled(MaterialUIBox)({
  padding: "12px",
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  "& .status": {
    borderRadius: '88px !important',
    display: 'flex !important',
    width: '119px !important',
    padding: '6px 20px !important',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    fontFamily: 'Poppins Arial, Helvetica, sans-serif',
    fontSize: '14px !important',
    fontStyle: 'normal',
    fontWeight: 600,
    cursor: "pointer"
  },
  "& .status1": {
    backgroundColor: '#ffd9df',
    color:"#E70E6B"
  },
  "& .status2": {
    backgroundColor: '#eee8f2',
    color: "#652786"
  },
  "& .status3": {
    backgroundColor: '#ffede4',
    color: "#E47004"
  }
})

// Customizable Area End