// Customizable Area Start
import React, { createRef } from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { getStorageData } from "framework/src/Utilities";

const configJSON = require("./config.js");

interface CountryData {
  id: string,
  type: string,
  attributes: {
    name: string,
    emoji_flag: string,
    country_code: string   
  }  
}

export interface ResponseContentData {
  id: string
  data: [];
};

export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes: any;
}

interface S {
  isLoading: boolean;
  selectOpen:boolean;
  countryFlagCode:Array<CountryData>;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  title: string ;
  middleName: string;
}

interface SS {
  id: any;
}

export default class ManageUsersController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCountryCodeFlagCallIds: string= "";
  postUserAPICallId: string = "";
  handleSetSubmittingCallback: Function = () => { };
 

  private childRef: React.RefObject<any>;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      isLoading: false,
      selectOpen: false,
      countryFlagCode:[],
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      title:"",
      middleName:"",
    };
    this.childRef = React.createRef();
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.CountryCodeApi();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
  
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (apiRequestCallId) {
        case this.postUserAPICallId:
            this.handleCreateUserResponse(responseJson);
            break;

        case this.getCountryCodeFlagCallIds:
            this.getCountryRes(responseJson);
            break;

        default:
          break;
      }
    }
  }

  handleCreateUserResponse = (response: any) => {
    this.setState({ isLoading: false });
    if (response?.data) {
      this.props.navigation.navigate("UserManagement");
      toast.success(`Invite sent to ${response.data.attributes.first_name}`);
      return;
    }
    if (response?.errors) {
      response.errors.map((err: { message: string }) => {
        toast.error(err.message);
      });
    }
  };

  handleSelectOpen = () => {
    this.setState({selectOpen:true})
  };

  handleSelectClose = () => {
    this.setState({selectOpen:false})
  };

CountryCodeApi=async()=>{
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  
  );
  requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
  );
  this.getCountryCodeFlagCallIds = requestMessage.messageId
  requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  configJSON.CountryFlagApiEndPoint
  );
  requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
}

getCountryRes=(responseJSON:ResponseContentData)=>{
  this.setState({countryFlagCode:responseJSON.data})
  }

 postUserForm = async (event?: any) => {
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    "token": await getStorageData("authToken")
  };

  const userApiData = {
    "account": {
        "first_name": event.firstName,
        "middle_name": event.middleName,
        "last_name": event.lastName,
        "prefix_title": event.teamId,
        "email": event.email,
        "full_phone_number": event.CountryCode + event.phone,
        "email_invite_check": event.email_invite_check ? "true" : "false",
        "phone_number": event.phone,
        "country_code": event.CountryCode,
    }
}
  
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.postUserAPICallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(userApiData)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.createUserEndPoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.postApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

getColor = (selectedValue: string | undefined, option: string) =>
  selectedValue?.toString() === option ? '#652786' : 'inherit';

hasError = (touched: boolean | undefined, error: any) => touched && Boolean(error);

}
// Customizable Area End
