import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import { CourseResponse } from "../../../components/src/ReusableEnums.web"
// Customizable Area Start
import React from "react";
import { setStorageData, getStorageData, removeStorageData } from "../../../framework/src/Utilities";

export interface CategoryType {
  attributes: {
    id: string;
    is_selected: boolean;
    name: string;
    image: {
      id: string;
      url: string;
    }
  }
  id: string;
  type: string;
}

// Customizable Area End
export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: string;
  location?: any;
  history?: string;
  match?: { params: string };
  formikProps: any;
  historyProp: any;
  handleThumbnailChange: any;
  handleLaunchDate: any;
  handleDueDate: any;
  handleTitle: (value: string) => void;
  onDraft: any
  handleToastMsg: (message: string) => void;
  handleFormValChange:(name:string,val:any)=>void;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  expanded: boolean;
  category: Array<CategoryType>;
  allExperts: any
  courseId: string;
  course: any;
  thumbnailImg: any;
  selectedExperts: any;
  launchDate: any;
  dueDate: any;
  userList: any;
  days: any,
  open: boolean,
  toastOpen: boolean,
  fileInput: any
  defaultExpert: {
    id: string,
    value: string
  };
  isSaveDraft: boolean;
  toastMsg: string;
  createTitle: string;
  editTrue: boolean;
  releaseScheduleUpdate: boolean;
  isDisabled: boolean;
  saveAndCloseModal: boolean;
  saveContentModal: boolean;
  duplicateContentModal:boolean;  
  RemovePhase:boolean;
  optionShow:boolean;
  anchorEl:boolean;
  isCheckedExpert:boolean;
  selectedCheckbox:string;
  placeholderSearchText:string;
  showThumbnailLibraryModal:boolean;
  ThumbnailId:number|null;
  thumbnailImageObj:{
    id:string,
    type:string,
    attributes:{
       title:string,
       created_at:string,
       image:{
           id:string,
           url:string,
           filename:string
       },
       created_by:string
    }  
  }
  // Customizable Area End
}
interface SS {
  id: number;
}
export default class CourseCreationFormTwoController extends BlockComponent<
  Props,
  S,
  SS
> {
  expertApiCallId: string = ""
  getCategoryApiCallId: string = "";
  getAdminUserListApiCallId: string = "";
  getCourseListApiCallId: string ="";
  getUpdataNewDataApiCallId: string = "";
  duplicateCoursesApiRequestId:string = "";
  getPreviewApiCallId:string="";
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    ];
    this.state = {
      category: [],
      expanded: true,
      allExperts: [],
      courseId: "",
      course: "",
      selectedExperts: '',
      launchDate: null,
      dueDate: null,
      userList: [],
      days: "",
      open: false,
      toastOpen: false,
      thumbnailImg: "",
      fileInput: React.createRef(),
      defaultExpert: {
        id: "",
        value: ""
      },
      isSaveDraft: false,
      toastMsg: "",
      createTitle: "",
      editTrue: false,
      isDisabled: true,
      saveAndCloseModal: false,
      saveContentModal:false,
      duplicateContentModal:false,
      RemovePhase:true,
      releaseScheduleUpdate: false,
      optionShow:false,
      anchorEl:false,
      isCheckedExpert:false,
      selectedCheckbox:"",
      placeholderSearchText:"",
      showThumbnailLibraryModal:false,
      ThumbnailId:null,
      thumbnailImageObj:{
        id:"",
        type:"",
        attributes:{
           title:"",
           created_at:"",
           image:{
               id:"",
               url:"",
               filename:""
           },
           created_by:""
        }  
      }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);

  }

  async receive(from: any, message: any) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.expertApiCallId) {
        this.setState({ allExperts: responseJson.data })
      }
      if (apiRequestCallId === this.getCategoryApiCallId) {
        this.handleGetCategoryResponse(responseJson);
      }
      if (apiRequestCallId === this.getAdminUserListApiCallId) {

        if (responseJson.data && responseJson.data.length > 0) {
          const formattedData = responseJson.data.map((item: any, index: any) => ({
            value: index, // zero-based index
            label: item.attributes.full_name,
            labelValue: item.attributes.id
          }));

          this.setState({ userList: formattedData });
        }
      }
      if (apiRequestCallId === this.duplicateCoursesApiRequestId){
        this.handleDuplicateResponse(responseJson)
      }
    }

    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    this.getCourseList()
    this.getUpdateNewData()
    removeStorageData("editCoures");
    let FirstName = localStorage.getItem("first_name");
    let expert = localStorage.getItem("expert_id");
    this.setState({
      defaultExpert: {
        id: "",
        value: FirstName ? FirstName : ""
      }
    })
    if (this.props?.navigation.getParam("type") === "editCourse") {    
      this.setState({
        editTrue: true
      })
    }
    this.props.formikProps.setFieldValue("expert_id",expert)
    this.getExpertSearchListNew()
    this.getAdminUserList("")
    this.getAllCategory()
  }
  handlePhaseCancel=()=>{
  this.props.formikProps?.setFieldValue("content_release_schedule", true) 
  this.setState({RemovePhase:false})
  }
  handleRemovePhase=()=>{
    this.setState({RemovePhase:false})
  }
  getExpertSearchListNew = () => {
    const token = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.expertApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_library2/experts"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getAllCategory() {
    if (typeof (this.props?.location?.state?.item) !== undefined) {
      this.setState({
        courseId: this.props.location?.state?.item,
      })
    }

    this.doGetCategoryUserFn({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.categoryFetchAPiEndPoint,
    });
  }
handleShow=()=>{
  this.setState({optionShow:true})
}
handleShowClose=()=>{
  this.setState({optionShow:false})
}
  handleSelectChange = (event: any) => {
    this.setState({ selectedExperts: event.target.value });
  };
  handleOpenThumbnail=()=>{

    this.setState({showThumbnailLibraryModal:true})
  }

   
  handleThumbnailModalClose=(objectData:any)=>{
      this.props.formikProps.setFieldValue("thumbnail_img",this.state.thumbnailImageObj.attributes.image.url)
    this.setState({showThumbnailLibraryModal:false,thumbnailImageObj:objectData},()=>{
    this.props.handleThumbnailChange(this.state.thumbnailImageObj.id);
    })
  }
  onChange = (event: React.ChangeEvent<HTMLInputElement>, handleChange: { (event: React.ChangeEvent<HTMLInputElement>): void; (arg0: any): void; }, handleToastMsg: { (message: string): void; (arg0: string): void; }) => {
    let { value } = event.target
    this.setState((prevState) => ({
      ...prevState,
      createTitle: value,
    }));
    this.props.handleFormValChange("title",value)
    this.props.handleTitle(value)
    handleChange(event);
    handleToastMsg(event.target.value);
  };
  handleLaunchDateChangeOne = (value: any) => {
    this.setState({ launchDate: value });
    this.props.handleLaunchDate(value)
  };
  handleDueDateChangeOne = (value: any) => {
    this.setState({ dueDate: value });
    this.props.handleDueDate(value)
  };
  handleDays = (e: any) => {
    const newValue = parseInt(e.target.value, 10);
    if (newValue < 0) {
      e.target.value = '0';
    }
    this.setState({ days: e.target.value })
  }
  renderNotifyValues=(selected:any)=>{
    if (selected.length === 0) {
      return <em style={{fontStyle:"normal"}}>Select user</em>;
        }
        let selectedLabels = this.state.userList
        .filter((option:any) => selected.includes(option.labelValue))
        .map((option:any) => option.label);
        if (selectedLabels.length === 1) {
         return <span>{selectedLabels[0]}</span>;
       } else {
         return <span>{selectedLabels[0]} & {selectedLabels.length - 1} more</span>;
       }
  }

  renderDaily = () => {
    return this.props.formikProps?.values.content_release_schedule_value !== "daily" &&  "calender-btn" || "calender-btnoutlined" 
  }
  renderWeekly = () => {
    return this.props.formikProps?.values.content_release_schedule_value !== "weekly" &&  "calender-btn"||"calender-btnoutlined"
  }
  renderMonthy = () => {
    return this.props.formikProps?.values.content_release_schedule_value !== "monthly" && "calender-btn" || "calender-btnoutlined" 
  }
  checkCourseTimeDisableNew = () => {
    return this.props.formikProps?.values.add_due_date || !this.props.formikProps?.values.days_to_complete_the_course
  }
  onClickedDraftOne = () => {
    this.props.onDraft(true);
    this.setState({
      open: true
    });
  }
  handleCancelClose = () => {
    this.setState({
      open: false,
    });
  };

  handleCloseDraft = () => {
    setStorageData("isSaveDraft", "true")
    this.setState({
      open: false,
    }, () => {
      this.props.formikProps.handleSubmit();
    });
  };

  handleContinue = () => {
    setStorageData("isSaveDraft", "false")
    removeStorageData("EditCourseContent")
    this.props.formikProps.handleSubmit();
  };

  backColor = (value: any) => {
    return value ? "#FCF6F6" : "#E5E2E1"
  }
  TextColor=(value: any)=>{
    return value?"#ADAAAA":"#787776"
  }

  dropdownHandlerOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({
      anchorEl:!this.state.anchorEl,

    })
  }

  dropdownHandlerClose = () => {
    this.setState({
      anchorEl: false,
    })
  }

  placeholderCheckboxChangeHandler = (event:any, optionId:any) => {
    const { checked } = event.target;
    if(checked){
      this.props.handleFormValChange("expert_id",optionId)
      this.setState({
        anchorEl:false
      })
    }else{
      this.props.handleFormValChange("expert_id","")
      this.setState({
        anchorEl:false
      })
    }
  };
  handlePlaceholderSearchChange=(event:any)=>{
  this.setState({placeholderSearchText:event.target.value})
  }
  getUpdateNewData() {
    this.doUpdateNewApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: "",
    });
  }
  doUpdateNewApiCall = async (data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) => {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
    };
    const requestMessageUpdate = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessageUpdate.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getUpdataNewDataApiCallId = requestMessageUpdate.messageId
    requestMessageUpdate.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessageUpdate.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessageUpdate.id, requestMessageUpdate);
    return requestMessageUpdate.messageId;
  }

  doGetCategoryUserFn = async (data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) => {
    let { contentType, method, endPoint } = data;
    let header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    this.getCategoryApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    this.getCategoryApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getAdminUserList(data: any) {
    this.doGetAdminUserList({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getAdminNotificationList,
    });
  }
  doGetAdminUserList = async (data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) => {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getAdminUserListApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getCourseList() {
    this.doCourseListApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: "",
    });
  }
  doCourseListApiCall = async (data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) => {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCourseListApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }


  btnBackColor = () => {
    return this.checkValue() ? "#4D0F6C" : "#E5E2E1"
  }
  btnTextColor = () => {
    return this.checkValue() ? "#fff" : "#929090"
  }


  checkValue = () => {
    return (this.props.formikProps?.values?.title != "" || this.state.createTitle!= "" )
    && this.props.formikProps?.values?.expert_id != ""  
    &&(this.state.thumbnailImageObj.attributes.image.url ? this.state.thumbnailImageObj.attributes.image.url !=="":this.props?.formikProps?.values?.thumbnail_img !=="") 
  }
  closeModal=()=>{
    this.setState({showThumbnailLibraryModal:false})
  }
  clickedCloseSave = () => {
    this.setState({
      saveAndCloseModal: true
    })
  }

  onCloseModal = () => {
    this.setState({
      saveAndCloseModal: false
    })
  }

  handleCancel = () => {
    this.setState({
      saveAndCloseModal: false
    })
  }

  handleDiscard = () => {
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), "ContentManagement");
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  }

  onCloseContentModal = () => {
    this.setState({
      saveContentModal: false
    })
  }

  handleCancelContent = () => {
    this.setState({
      saveContentModal: false
    })
  }

  handleSaveContent = () => {
    this.setState({
      saveContentModal: false
    })
    removeStorageData("isSaveDraft")
    this.props.formikProps.handleSubmit()
  }
  handleSaveData=()=>{
    setStorageData("save&close","true")
    this.setState({
      saveAndCloseModal: false
    })
    this.props.formikProps.handleSubmit()
  }
  handleEditContent = () => {
    this.setState({
      saveContentModal: true
    })
  }

  selectCategoryFnOne = (index: number, categories: Array<CategoryType>) => {
    let items = [...categories];
    let item = { ...items[index] };
    item.attributes.is_selected = !item.attributes.is_selected;
    items[index] = item;
    this.setState({ category: items });
    this.props.handleFormValChange("categories", items)
  };

  handleEditMode = () => {
    this.setState({
      isDisabled: false
    })
  }
  handleDuplicateContent = () => {
    this.setState({
      duplicateContentModal: true
    })
  }
  handleCloseDuplicateContent = () => {
    this.setState({
      duplicateContentModal: false
    })
  }
  handleDuplicateSubmit = ()=>{
    const editId = this.props?.navigation.getParam("id");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.duplicateCoursesApiRequestId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.duplicateCourseApi}/${editId}/duplicate_course`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  handleDuplicateResponse = (responseJson:CourseResponse) =>{
    if(responseJson.data){
      this.props.navigation.navigate("CourseCreation", { id : responseJson.data.id , type:"addCourse"});
      this.handleCloseDuplicateContent();
      toast.success(configJSON.duplicateSuccessTxt);
    }else{
      toast.error("Record not found");
    }
  }
  handleGetCategoryResponse= (responseJson:any)=>{
    const selectedCategories = Array.isArray(this.props.formikProps?.values?.category) ? this.props.formikProps.values.category : [];
        const data = Array.isArray(responseJson.data) ? responseJson.data : [];
        const items = data.map((item: any) => {
          if(selectedCategories.some((selected: { name: string; }) => selected?.name === item.attributes?.name)){
            item.attributes.is_selected = !item.attributes.is_selected;
          }else {
             item.attributes.is_selected = false;
          }
          return item;
        });
        this.setState({ category: items })
  }
  // Customizable Area End
}









